import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule } from '@angular/forms';
import { SkeletonLoadingModule } from './v2/shared/skeleton-loading/skeleton-loading.module';
import { AutofocusFixModule } from 'ngx-autofocus-fix';

// Angular Material
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { LayoutModule } from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';

//V1
import { TraineesComponent } from './v1/trainees/trainees.component';
import { Vis001caveComponent } from './v1/courses/vis001cave/vis001cave.component';
import { Bos002Component } from './v1/courses/bos002/bos002.component';

import { MainNavComponent } from './v2/shared/main-nav/main-nav.component';
import { LoginComponent } from './v2/shared/login-components/login/login.component';
import { LogoutComponent } from './v2/shared/login-components/logout/logout.component';
import { AdminDashboardComponent } from './v2/shared/admin-dashboard/admin-dashboard.component';
import { CoursesComponent } from './v2/trainer/courses-list-v2/courses.component';
import { ChangePasswordComponent } from './v2/shared/change-password/change-password.component';
import { ChartsModule , ThemeService } from 'ng2-charts';
import { PrivacyComponent } from './v2/shared/privacy/privacy.component';
import { LegalComponent } from './v2/shared/legal/legal.component';
import { VerifyEmailComponent } from './v2/shared/account-verify/verify-email/verify-email.component';
import { ForgotPasswordComponent } from './v2/shared/account-verify/forgot-password/forgot-password.component';

//V2
import { Vis2Component } from './v2/trainer/courses-v2/vis2/vis2.component';
import { TraineesV2Component } from './v2/trainer/trainees-v2/trainees-v2.component';
import { TraineesDetailsV2Component } from './v2/trainer/trainees-details-v2/trainees-details-v2.component';
import { ClientsComponent } from './v2/superadmin/clients/clients.component';
import { NotFoundComponent } from './v2/shared/not-found/not-found.component';
import { TraineesListV2Component } from './v2/trainer/trainees-list-v2/trainees-list-v2.component';
import { ForgotPasswordComponentV2 } from './v2/shared/login-components/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './v2/shared/login-components/new-password/new-password.component';
import { TraineeRegistrationComponent } from './v2/trainee/trainee-registration/trainee-registration.component';
import { MyDetailsComponent } from './v2/trainee/my-details/my-details.component';
import { TrainerRegistrationComponent } from './v2/trainer/trainer-registration/trainer-registration.component';
import { BosComponent } from './v2/trainer/courses-v2/bos/bos.component';
import { NewPinComponent } from './v2/shared/login-components/new-pin/new-pin.component';
import { ExpiredLicenseComponent } from './v2/shared/expired-liscence/expired-liscence.component';
import { FptComponent } from './v2/trainer/courses-v2/fpt/fpt.component';
import { GreComponent } from './v2/trainer/courses-v2/gre/gre.component';
import { RaComponent } from './v2/trainer/courses-v2/ra/ra.component';
import { ModuleListV2Component } from './v2/trainer/module-list-v2/module-list-v2.component';
import { JanComponent } from './v2/trainer/courses-v2/jan/jan.component';
import { DepComponent } from './v2/trainer/courses-v2/dep/dep.component';
import { AvatComponent } from './v2/trainer/courses-v2/avat/avat.component';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    AdminDashboardComponent,
    LoginComponent,
    LogoutComponent,
    TraineesComponent,
    CoursesComponent,
    ChangePasswordComponent,
    Vis001caveComponent,
    PrivacyComponent,
    LegalComponent,
    Bos002Component,
    VerifyEmailComponent,
    ForgotPasswordComponent,
    Vis2Component,
    TraineesV2Component,
    TraineesDetailsV2Component,
    ClientsComponent,
    NotFoundComponent,
    TraineesListV2Component,
    ForgotPasswordComponentV2,
    NewPasswordComponent,
    TraineeRegistrationComponent,
    MyDetailsComponent,
    TrainerRegistrationComponent,
    BosComponent,
    NewPinComponent,
    ExpiredLicenseComponent,
    FptComponent,
    GreComponent,
    RaComponent,
    ModuleListV2Component,
    JanComponent,
    DepComponent,
    AvatComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ChartsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    MatDialogModule,
    MatGridListModule,
    MatExpansionModule,
    NgCircleProgressModule.forRoot(),
    MatSelectModule,
    FormsModule,
    SkeletonLoadingModule,
    MatCheckboxModule,
    MatTooltipModule,
    AutofocusFixModule.forRoot()
  ],
  providers: [ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
