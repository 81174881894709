<app-main-nav>
  <div fxLayout="row" fxLayoutAlign="center" *ngIf="showAddAdminPanel && !viewAdminPanel" class="lpopup" [ngClass.lt-lg]="'popup-smallScreen'">
    <div fxLayout="column" fxLayoutGap="10%" fxFlex="99">
      <span class="med-heading-text-config" style="margin-right:auto;">Enter admin details</span>
      <form [formGroup]="personalForm" (ngSubmit)="addAdmin()" validate fxLayout="column">
        <mat-label class="non-floating-label"> Email address </mat-label>
        <mat-form-field floatLabel="always">
            <div class="input-field">
              <input class="input-text" autofocus matInput type="text" formControlName="email">
            </div>
        </mat-form-field>
        <span *ngIf="personalForm.controls.email.touched && personalForm.controls.email.hasError('email')" class="error-message">Enter a valid email address.</span>
        <mat-label class="non-floating-label"> First Name </mat-label>
        <mat-form-field floatLabel="always">
            <div class="input-field">
              <input class="input-text" matInput type="text" formControlName="fname">
            </div>
        </mat-form-field>
        <mat-label class="non-floating-label"> Surname</mat-label>
        <mat-form-field floatLabel="always" >
          <div class="input-field" >
            <input class="input-text" matInput type="text" formControlName="lname">
          </div>
        </mat-form-field>
        <button class="button"  mat-raised-button type="submit" [disabled]="this.personalForm.invalid">SEND INVITATION</button>
      </form>
      <div fxLayout="row" *ngIf="inviteSent">
        <img src="/assets/2.0/AVA_tick_icon.svg" fxFlex="10%" style="height: 20px;">
        <span class="small-text-config"> Invitation sent to {{personalForm.controls['email'].value}}</span>
      </div>
      <span class="small-text-config" *ngIf="existsError"> {{errorToShow}} </span>
    </div>
    <img src="/assets/2.0/AVA_close_icon.svg" style="z-index: 1; height: 50px; position:relative; margin: -9% -23% 1% -5%; cursor:pointer;" (click)="closePopup()">
  </div>

  <div fxLayout="row" fxLayoutAlign="center" *ngIf="viewAdminPanel && !showAddAdminPanel" class="wpopup" [ngClass.lt-lg]="'popup-smallScreen1'" >
    <div fxLayout="column" fxLayoutGap="5%" fxFlex="99">
      <span class="med-heading-text-config" style="margin-right:auto;">Administrators</span>
      <div class = "table-config" style="width: 100%;">
        <mat-table [dataSource]="dataSource"  style="width: 100%;" [ngClass.lt-lg]="'popup-smallScreen1'">
            <ng-container matColumnDef="Name">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Name </mat-header-cell>
              <mat-cell class="table-data-font" *matCellDef="let row" [contentEditable]="rowID === row.userID" (input)="onNameChange($event.target.innerHTML)"> {{row.Name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Email">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Email </mat-header-cell>
              <mat-cell class="table-data-font" *matCellDef="let row" (input)="onEmailChange($event.target.innerHTML)"> {{row.Email}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Operations">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Operations </mat-header-cell>
              <mat-cell class="table-data-font" *matCellDef="let row">
                <button mat-button (click)="makeEditable(row)" *ngIf="!fadeButton"><mat-icon>create</mat-icon></button>
                <button mat-button (click)="editRow(row)" *ngIf="fadeButton"><mat-icon>done_outline</mat-icon></button>
                <button mat-button (click)="deleteRow(row)" [disabled]="fadeButton"><mat-icon>delete</mat-icon></button>
              </mat-cell>
            </ng-container>

            <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
      </div>
    </div>
    <img src="/assets/2.0/AVA_close_icon.svg" style="z-index: 1; height: 50px; position:relative; margin: -7% -20% 1% -5%; cursor:pointer;" (click)="closePopup()">
  </div>

  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="end start"    style="margin: 1% 2% 0% 0%;" *ngIf="clientName != 'VIS'" fxLayoutAlign.lt-md="start start">
      <mat-form-field floatLabel="never" appearance="fill" fxFlex="15" class="dropdown" fxFlex.lt-md="98">
        <mat-label style="font-family: 'Libre Franklin'; color:white;" [ngClass]="selectedValue?'hidden':''">ADMIN</mat-label>
        <mat-select [(value)]="selectedValue" (selectionChange)="dropdownOptions($event.value)">
          <mat-option value="add" [ngClass]="(selectedValue=='add')?'dropdown-selected-text':''">Add Administrator</mat-option>
          <mat-option value="view">View Administrator</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-icon class="dropdown-arrow" [ngClass.lt-lg]="'dropdown-arrow-smallScreen'">keyboard_arrow_down</mat-icon>
    </div>
    <div fxLayout="row" fxLayoutAlign="stretch" flex fxLayout.lt-lg="column" [style.opacity]="fadeOut?'.2':null" [ngClass]="fadeOut? 'main-container pointer-events': 'main-container'">
        <div class = "sub-container" fxFlex="50" fxLayout="column" fxFlexAlign="stretch">
            <div class="med-text-config" fxLayout="column" fxLayoutAlign="center start">Session analytics</div>
            <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start stretch" >
                <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg] ="'card-column'" fxFlex.md="40%">
                    <mat-card-title class="card-heading-config" [ngClass.lt-lg] ="'card-column-heading'" >
                        <img src="/assets/icon/dashboard_sessions_icon.svg" style="height: 30px; vertical-align: top;"> &nbsp;
                        <span>Training</span>
                    </mat-card-title>
                    <div *ngIf="isSessionLoading" style="margin-left:5%; margin-top: 12%;">
                      <app-skeleton-loading Cwidth="230" Cheight="115">
                      </app-skeleton-loading>
                    </div>
                    <mat-card-content class="card-content-config" [ngClass.lt-md] ="'card-column-content'" >{{totalSessions}}</mat-card-content>
                    <span class="card-secondary-content-config" style="margin:-50% 0% 0% 15%">sessions</span>
                    <mat-card-content class="card-secondary-content-config" [ngClass.lt-md] ="'card-column-secondary-content'">
                      <div fxLayout="row" *ngIf="!isSessionLoading">
                        <p [ngClass]="sessionsUpdate >= 0 ? 'positive' : 'negative'">
                          <span *ngIf = "sessionsUpdate > 0">+</span>{{sessionsUpdate}}%
                        </p>&nbsp;
                        <p>on last month</p>
                      </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card-config" fxFlex="45%" [ngClass.lt-md] ="'card-column'" fxFlex.md="40%">
                    <mat-card-title class="card-heading-config" [ngClass.lt-md] ="'card-column-heading'" >
                        <img src="/assets/icon/menu_trainees_icon.svg" style="height: 30px; vertical-align: top;"> &nbsp;
                        <span>Guests</span>
                    </mat-card-title>
                    <div *ngIf="isSessionLoading" style="margin-left:5%; margin-top: 12%;">
                      <app-skeleton-loading Cwidth="230" Cheight="115">
                      </app-skeleton-loading>
                    </div>
                    <mat-card-content class="card-content-config" [ngClass.lt-md] ="'card-column-content'" >{{totalGuests}}</mat-card-content>
                    <span class="card-secondary-content-config" style="margin:-50% 0% 0% 15%">sessions</span>
                    <mat-card-content class="card-secondary-content-config" [ngClass.lt-md] ="'card-column-secondary-content'">
                      <div fxLayout="row" *ngIf="!isSessionLoading">
                        <p [ngClass]="guestUpdate >= 0 ? 'positive' : 'negative'">
                          <span *ngIf = "guestUpdate > 0">+</span>{{guestUpdate}}%
                        </p>&nbsp;
                        <p>on last month</p>
                      </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card-config" fxFlex="45%" [ngClass.lt-md] ="'card-column'" fxFlex.md="40%">
                    <mat-card-title class="card-heading-config" [ngClass.lt-md] ="'card-column-heading'" >
                        <img src="/assets/2.0/AVA_duration_icon.svg" style="height: 30px; vertical-align: top;"> &nbsp;
                        <span>Training Time</span>
                    </mat-card-title>
                    <div *ngIf="isSessionLoading" style="margin-left:5%; margin-top: 12%;">
                      <app-skeleton-loading Cwidth="230" Cheight="115">
                      </app-skeleton-loading>
                    </div>
                    <mat-card-content class="card-time-content-config" [ngClass.lt-md] ="'card-column-content'" >{{totalTime}}</mat-card-content>
                    <mat-card-content class="card-secondary-content-config" [ngClass.lt-md] ="'card-column-secondary-content'">
                      <div fxLayout="row" *ngIf="!isSessionLoading">
                        <p [ngClass]="timeUpdate >= 0 ? 'positive' : 'negative'">
                          <span *ngIf = "timeUpdate > 0">+</span>{{timeUpdate}}%
                        </p>&nbsp;
                        <p>on last month</p>
                      </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card-config" fxFlex="45%" [ngClass.lt-md] ="'card-column'" fxFlex.md="40%">
                  <mat-card-title class="card-heading-config" [ngClass.lt-md] ="'card-column-heading'" >
                      <img src="/assets/2.0/AVA_duration_icon.svg" style="height: 30px; vertical-align: top;"> &nbsp;
                      <span>Guest Time</span>
                  </mat-card-title>
                  <div *ngIf="isSessionLoading" style="margin-left:5%; margin-top: 12%;">
                    <app-skeleton-loading Cwidth="230" Cheight="115">
                    </app-skeleton-loading>
                  </div>
                  <mat-card-content class="card-time-content-config" [ngClass.lt-md] ="'card-column-content'" >{{guestTime}}</mat-card-content>
                  <mat-card-content class="card-secondary-content-config" [ngClass.lt-md] ="'card-column-secondary-content'">
                    <div fxLayout="row" *ngIf="!isSessionLoading">
                      <p [ngClass]="guestTimeUpdate >= 0 ? 'positive' : 'negative'">
                        <span *ngIf = "guestTimeUpdate > 0">+</span>{{guestTimeUpdate}}%
                      </p>&nbsp;
                      <p>on last month</p>
                    </div>
                  </mat-card-content>
              </mat-card>
            </div>
        </div>
        <div class = "sub-container" fxFlex="50" fxFlexAlign="stretch">
            <div class="med-text-config" fxLayout="column" fxLayoutAlign="center start">User analytics</div>
            <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start stretch" >
                <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg] ="'card-column'" fxFlex.md="40%">
                    <mat-card-title class="card-heading-config" [ngClass.lt-lg] ="'card-column-heading'" >
                        <img src="/assets/2.0/AVA_duration_icon.svg" style="height: 30px; vertical-align: top;"> &nbsp;
                        <span>AVG Time</span>
                    </mat-card-title>
                    <div *ngIf="isUserLoading" style="margin-left:5%; margin-top: 12%;">
                      <app-skeleton-loading Cwidth="230" Cheight="115">
                      </app-skeleton-loading>
                    </div>
                    <mat-card-content class="card-time-content-config" [ngClass.lt-lg] ="'card-column-content'" >{{avgTime}}</mat-card-content>
                    <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg] ="'card-column-secondary-content'">
                      <div fxLayout="row" *ngIf="!isUserLoading">
                        <p [ngClass]="avgTimeUpdate >= 0 ? 'positive' : 'negative'">
                          <span *ngIf = "avgTimeUpdate > 0">+</span>{{avgTimeUpdate}}%
                        </p>&nbsp;
                        <p>on last month</p>
                      </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg] ="'card-column'" fxFlex.md="40%">
                    <mat-card-title class="card-heading-config" [ngClass.lt-lg] ="'card-column-heading'" >
                        <img src="/assets/icon/menu_trainees_icon.svg" style="height: 30px; vertical-align: top;"> &nbsp;
                        <span>Users</span>
                    </mat-card-title>
                    <div *ngIf="isUserLoading" style="margin-left:5%; margin-top: 12%;">
                      <app-skeleton-loading Cwidth="230" Cheight="115">
                      </app-skeleton-loading>
                    </div>
                    <mat-card-content class="card-content-config" [ngClass.lt-lg] ="'card-column-content'" >{{users}}</mat-card-content>
                    <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg] ="'card-column-secondary-content'">
                      <div fxLayout="row" *ngIf="!isUserLoading">
                        <p [ngClass]="usersUpdate >=0 ? 'positive' : 'negative'">
                          <span *ngIf = "usersUpdate > 0">+</span>{{usersUpdate}}%
                        </p>&nbsp;
                        <p>on last month</p>
                      </div>
                    </mat-card-content>
                </mat-card>
                <!-- <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg] ="'card-column'" fxFlex.md="40%">
                    <mat-card-title class="card-heading-config" [ngClass.lt-lg] ="'card-column-heading'" >
                        <img src="/assets/2.0/AVA_geolocation.svg" style="height: 30px; vertical-align: top;"> &nbsp;
                        <span>Location</span>
                    </mat-card-title>
                    <div *ngIf="isUserLoading" style="margin-left:5%; margin-top: 12%;">
                      <app-skeleton-loading Cwidth="230" Cheight="115">
                      </app-skeleton-loading>
                    </div>
                    <mat-card-content class="chart-config">
                        <canvas id="geo-chart" height="150">{{geoChart}}</canvas>
                      </mat-card-content>
                </mat-card> -->
            </div>
        </div>
    </div>

  </div>
</app-main-nav>
