import { Component, OnInit } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  
  userID = null;
  mobileScreen = false;
  hidepwd = true;
  hideconpwd = true;
  resetPassword;
  userOrg: null;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private _httpService: HttpService,
    private _sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userID = this.activatedRoute.snapshot.params["userID"];
    this.userOrg = this.activatedRoute.snapshot.params["org"];
    if(window.innerWidth <= 575){
      this.mobileScreen = true;
    }
  }
  public passwordForm = this.formBuilder.group({
    pwd        : ['', Validators.compose([
      Validators.required, 
      Validators.minLength(8), 
      this.regexValidator(new RegExp('[0-9]'), {'nums': true}),
      this.regexValidator(new RegExp('[a-z]'), {'lower': true}),
      this.regexValidator(new RegExp('[A-Z]'), {'upper': true}),
      this.regexValidator(new RegExp('[-\/:-@\[-\`{-~!()&*£$%#<>.]'), {'special': true}),
    ])],
    conpwd : ['', Validators.compose([Validators.required, Validators.minLength(8)])]
    }, {
      validator: this.confirmPasswordMatch('pwd', 'conpwd')
  });

  confirmPasswordMatch(controlName: string, matchingControlName: string) {    
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmPasswordMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        if (!control.value) {
            return null;
        }
        const valid = regex.test(control.value);
        return valid ? null : error;
    }
}

  reset(){
    this.resetPassword={
      "userID": this.userID,
      "password": this.passwordForm.controls['pwd'].value,
      "org": this.userOrg
    }
    this._httpService.updatePasswordV2(this.resetPassword)
      .subscribe(data=>{
        if(data=='Same Password'){
          this.snackBar.open('Entered password is same as old password. Please enter a different password.', 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else{
          this.snackBar.open('Password reset successful', 'Close', {
            duration: 3000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
          this.router.navigateByUrl("");
        }
      }, error => {
        // console.log("Reset password Error", error);
      })
}
}
