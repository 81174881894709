<app-main-nav>
  <div class="main-container">
    <div>
      <div>
        <div class="text-config">Courses</div>
        <div class="med-text-config">List of learning content</div>
        <div  fxLayoutAlign="flex-start" fxLayout.lt-md="column"  class="search-bar-config">
            <mat-form-field style="min-width: 5%; max-width: 93%;">
              <input class="input-config" (keyup)="searchTable($event.target.value)" matInput>
              <mat-icon class="mat-icon-config" matSuffix>search</mat-icon>
            </mat-form-field>
          </div>   
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
      <div class = "table-config-course" *ngIf="userRole!='IMT' && userRole!='IMTLCETB'" fxFlex="40">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="Name">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Courses </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Name}} </mat-cell>
                </ng-container>
<!--   
                <ng-container matColumnDef="Modules">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef> Modules </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Modules}} </mat-cell>
                </ng-container> -->
  
                  <!-- <ng-container matColumnDef="Users">
                    <mat-header-cell class="table-header-font" *matHeaderCellDef> Users </mat-header-cell>
                    <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Users}} </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="Duration">
                    <mat-header-cell class="table-header-font" *matHeaderCellDef> Duration </mat-header-cell>
                    <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Duration}} </mat-cell>
                  </ng-container> -->
  
                <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showModules = !showModules"></mat-row>
              </mat-table>
  
              <div *ngIf="isLoadingCourse" style="margin-left:0.5%; margin-top: 0.5%;">
                <div *ngFor="let _ of [].constructor(1)">
                  <app-skeleton-loading Cwidth="460" Cheight="48"><br><br>
                  </app-skeleton-loading>
                </div>
              </div>
              <!-- <mat-paginator #paginator class="paginator-class" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator> -->
      </div>
      <div class = "table-config-module" *ngIf="userRole!='IMT' && userRole!='IMTLCETB' && showModules" fxFlex="60">
        <mat-table [dataSource]="dataSourceModules" matSort>
          <ng-container matColumnDef="FullName">
            <mat-header-cell class="table-header-font" *matHeaderCellDef> Modules </mat-header-cell>
            <mat-cell class="table-data-font"  style="background-color: #e4f1ef;" *matCellDef="let row"> {{row.FullName}} </mat-cell>
          </ng-container>

            <ng-container matColumnDef="Users">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> <img src="/assets/2.0/AVA_trainees_icon.svg"></mat-header-cell>
              <mat-cell class="table-data-font"  style="background-color: #e4f1ef;" *matCellDef="let row"> {{row.Users}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Duration">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> <img src="/assets/icon/Timer_icon.svg" style="margin-left: 40px; height: 30px;"> </mat-header-cell>
              <mat-cell class="table-data-font"  style="background-color: #e4f1ef;" *matCellDef="let row"> {{row.Duration}} </mat-cell>
            </ng-container>

          <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumnsModules"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsModules;" (click)="showDetails(row)"></mat-row>
        </mat-table>

          <div *ngIf="isLoadingModule" style="margin-left:0.5%; margin-top: 0.5%;">
            <div *ngFor="let _ of [].constructor(3)">
              <app-skeleton-loading Cwidth="700" Cheight="48"><br><br>
              </app-skeleton-loading>
            </div>
          </div>
          <!-- <mat-paginator #paginator class="paginator-class" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator> -->
  </div>
    </div>
  </div>
</app-main-nav>
