<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar style="padding-left: 0px;" class="white-font">
      <img class="logo-config" src="/assets/2.0/AVA_Logo_full-colour.svg" alt="AA"> Admin Portal
    </mat-toolbar>
    <mat-nav-list style="background-color: #021B36; margin-top: 15%;">
      <a routerLinkActive="active-link" class="white-font" mat-list-item routerLink="/Dashboard"  *ngIf="userType!='User'">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/icon/menu_dashboard_icon.svg"> &nbsp; &nbsp;  <span>Dashboard</span>
      </a>
      <a routerLinkActive="active-link" mat-list-item class="white-font" mat-list-item routerLink="/Courses"  *ngIf="userRole!='IMT' && userRole!='IMTLCETB' && userType=='Admin'">
        <img style="height: 30px; vertical-align:-0.1em;" src="assets/2.0/AVA_Course_Icon.svg"> &nbsp; &nbsp;  <span>Courses</span> &nbsp;
      </a>
      <!--############################################V2####################################################-->
      <!--#region Mersus  -->
      <a *ngIf="userRole=='Mersus' && userType=='Superadmin'" style="background-color: #021B36;">
        <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()">
          <!-- #enddocregion hide-toggle -->
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item>
              <img style="height: 23px; vertical-align:-0.1em;" src="assets/2.0/AVA_clients_icon.svg"> &nbsp; &nbsp; <span>Clients</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/V2/Clients']" [state]="{clientName: 'VIS'}" [queryParams]="{clientName: 'VIS'}" queryParamsHandling="merge">
            <span>VistaMed</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/V2/Clients']" [state]="{clientName: 'BOS'}" [queryParams]="{clientName: 'BOS'}" queryParamsHandling="merge">
            <span>Boston</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/V2/Clients']" [state]="{clientName: 'FPT'}" [queryParams]="{clientName: 'FPT'}" queryParamsHandling="merge">
            <span>First Polymer Training</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/V2/Clients']" [state]="{clientName: 'RA'}" [queryParams]="{clientName: 'RA'}" queryParamsHandling="merge">
            <span>Robotics Arm</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/V2/Clients']" [state]="{clientName: 'AVA'}" [queryParams]="{clientName: 'AVA'}" queryParamsHandling="merge">
            <span>AVA Demo</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/V2/Clients']" [state]="{clientName: 'JAN'}" [queryParams]="{clientName: 'JAN'}" queryParamsHandling="merge">
            <span>Janssen</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/V2/Clients']" [state]="{clientName: 'DEP'}" [queryParams]="{clientName: 'DEP'}" queryParamsHandling="merge">
            <span>DePuy</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/V2/Clients']" [state]="{clientName: 'IMT'}" [queryParams]="{clientName: 'IMT'}" queryParamsHandling="merge">
            <span>Imtech Skills</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/V2/Clients']" [state]="{clientName: 'IMTLCETB'}" [queryParams]="{clientName: 'IMTLCETB'}" queryParamsHandling="merge">
            <span>Imtech Skills LCETB</span>
            </a>
          </div>
        </mat-expansion-panel>
      </a>
      <!--#endregion -->

      <!--#region VIS  -->
      <!-- <a *ngIf="userRole=='VIS' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item>
              <img style="height: 23px; vertical-align:-0.1em;" src="assets/2.0/AVA_Course_Icon.svg"> &nbsp; &nbsp; <span>Courses</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="large-white-font" mat-list-item>
              <span>Catheter Assembly</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole == 'VIS' || userRole == 'Mersus'">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/CoursesV2/VIS2']" [state]="{courseName: 'M1'}" [queryParams]="{courseName: 'M1'}" queryParamsHandling="merge">
            <span>Welding</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole == 'VIS' || userRole == 'Mersus'">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/CoursesV2/VIS2']" [state]="{courseName: 'M2'}" [queryParams]="{courseName: 'M2'}" queryParamsHandling="merge">
            <span>Proximal Braid Termination</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole == 'VIS' || userRole == 'Mersus'">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/CoursesV2/VIS2']" [state]="{courseName: 'M3'}" [queryParams]="{courseName: 'M3'}" queryParamsHandling="merge">
            <span>Distal Braid Termination</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole == 'VIS' || userRole == 'Mersus'">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/CoursesV2/VIS2']" [state]="{courseName: 'M4'}" [queryParams]="{courseName: 'M4'}" queryParamsHandling="merge">
            <span>Fishing Wire</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole == 'VIS' || userRole == 'Mersus'">
            <a mat-list-item class="medium-white-font" mat-list-item [routerLink]="[ '/CoursesV2/VIS2']" [state]="{courseName: 'M5'}" [queryParams]="{courseName: 'M5'}" queryParamsHandling="merge">
            <span>Polymide Loading</span>
            </a>
          </div>
        </mat-expansion-panel>
        </mat-expansion-panel>
      </a> -->
      <!-- <a *ngIf="userRole=='VIS' && userType=='Admin'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/ActivityV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_activity.svg"> &nbsp; &nbsp; <span>Activity</span>
      </a> -->
      <a *ngIf="userRole=='VIS' && userType=='Admin'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/TraineesListV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp; &nbsp; <span>Trainees</span>
      </a>
      <!--#endregion -->

      <!--#region BOS  -->
      <!-- <a *ngIf="userRole=='BOS' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item>
              <img style="height: 23px; vertical-align:-0.1em;" src="assets/2.0/AVA_Course_Icon.svg"> &nbsp; &nbsp;  <span>Courses</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="large-white-font" mat-list-item>
              <span>Line Clearance</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='BOS'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/BOS']" [state]="{courseName: 'M1'}" [queryParams]="{courseName: 'M1'}" queryParamsHandling="merge">
            <span>Initial Line Clearance</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='BOS'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/BOS']" [state]="{courseName: 'M2'}" [queryParams]="{courseName: 'M2'}" queryParamsHandling="merge">
            <span>MES & Line Clearance</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='BOS'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/BOS']" [state]="{courseName: 'M3'}" [queryParams]="{courseName: 'M3'}" queryParamsHandling="merge">
            <span>End of Station</span>
            </a>
          </div>
        </mat-expansion-panel>
        </mat-expansion-panel>
      </a>
       <a *ngIf="userRole=='BOS' && userType=='Admin'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/ActivityV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_activity.svg"> &nbsp; &nbsp; <span>Activity</span>
      </a> -->
      <a *ngIf="userRole=='BOS' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel  style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
        (mouseleave)="panel.close()" hideToggle="true" [expanded]="true" [disabled]="true">
          <mat-expansion-panel-header  style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: false}" [queryParams]="{openPopup: false}">
              <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp; &nbsp; <span>Trainees</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='BOS'">
            <a mat-list-item class="large-white-font" [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: true}" [queryParams]="{openPopup: true}">
              <span>Add Trainees</span>
            </a>
          </div>
        </mat-expansion-panel>
      </a>
      <!--#endregion -->

      <!--#region FPT  -->
      <!-- <a *ngIf="userRole=='FPT' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item>
              <img style="height: 23px; vertical-align:-0.1em;" src="assets/2.0/AVA_Course_Icon.svg"> &nbsp; &nbsp;  <span>Courses</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="large-white-font" mat-list-item>
              <span>Injection Moulding</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='FPT'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/FPT']" [state]="{courseName: 'M1'}" [queryParams]="{courseName: 'M1'}" queryParamsHandling="merge">
            <span>Introduction to Injection Moulding</span>
            </a>
          </div>
        </mat-expansion-panel>
        </mat-expansion-panel>
      </a> -->
      <!-- <a *ngIf="userRole=='FPT' && userType=='Admin'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/ActivityV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_activity.svg"> &nbsp; &nbsp; <span>Activity</span>
      </a> -->
      <a *ngIf="userRole=='FPT' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel  style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
        (mouseleave)="panel.close()" hideToggle="true" [expanded]="true" [disabled]="true">
          <mat-expansion-panel-header  style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: false}" [queryParams]="{openPopup: false}">
              <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp; &nbsp; <span>Trainees</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='FPT'">
            <a mat-list-item class="large-white-font" [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: true}" [queryParams]="{openPopup: true}">
              <span>Add Trainees</span>
            </a>
          </div>
        </mat-expansion-panel>
      </a>
      <!--#endregion -->

      <!--#region AVA  -->
      <!-- <a *ngIf="userRole=='AVA' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item>
              <img style="height: 23px; vertical-align:-0.1em;" src="assets/2.0/AVA_Course_Icon.svg"> &nbsp; &nbsp;  <span>Courses</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="large-white-font" mat-list-item>
              <span>Course 1</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='AVA'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/AVA']" [state]="{courseName: 'M1'}" [queryParams]="{courseName: 'M1'}" queryParamsHandling="merge">
            <span>Module 1</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='AVA'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/AVA']" [state]="{courseName: 'M2'}" [queryParams]="{courseName: 'M2'}" queryParamsHandling="merge">
            <span>Module 2</span>
            </a>
          </div>
        </mat-expansion-panel>
        </mat-expansion-panel>
      </a> -->
      <!-- <a *ngIf="userRole=='AVA' && userType=='Admin'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/ActivityV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_activity.svg"> &nbsp; &nbsp; <span>Activity</span>
      </a> -->
      <a *ngIf="userRole=='AVA' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel  style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
        (mouseleave)="panel.close()" hideToggle="true" [expanded]="true" [disabled]="true">
          <mat-expansion-panel-header  style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: false}" [queryParams]="{openPopup: false}">
              <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp; &nbsp; <span>Trainees</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='AVA'">
            <a mat-list-item class="large-white-font" [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: true}" [queryParams]="{openPopup: true}">
              <span>Add Trainees</span>
            </a>
          </div>
        </mat-expansion-panel>
      </a>
      <!--#endregion -->

      <!--#region RA  -->
      <!-- <a *ngIf="userRole=='RA' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item>
              <img style="height: 23px; vertical-align:-0.1em;" src="assets/2.0/AVA_Course_Icon.svg"> &nbsp; &nbsp;  <span>Courses</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="large-white-font" mat-list-item>
              <span>Course 1</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='RA'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/RA']" [state]="{courseName: 'M1'}" [queryParams]="{courseName: 'M1'}" queryParamsHandling="merge">
            <span>Module 1</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='RA'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/RA']" [state]="{courseName: 'M2'}" [queryParams]="{courseName: 'M2'}" queryParamsHandling="merge">
            <span>Module 2</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='RA'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/RA']" [state]="{courseName: 'M3'}" [queryParams]="{courseName: 'M3'}" queryParamsHandling="merge">
            <span>Module 3</span>
            </a>
          </div>
        </mat-expansion-panel>
        </mat-expansion-panel>
      </a> -->
      <!-- <a *ngIf="userRole=='RA' && userType=='Admin'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/ActivityV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_activity.svg"> &nbsp; &nbsp; <span>Activity</span>
      </a> -->
      <a *ngIf="userRole=='RA' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel  style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
        (mouseleave)="panel.close()" hideToggle="true" [expanded]="true" [disabled]="true">
          <mat-expansion-panel-header  style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: false}" [queryParams]="{openPopup: false}">
              <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp; &nbsp; <span>Trainees</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='RA'">
            <a mat-list-item class="large-white-font" [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: true}" [queryParams]="{openPopup: true}">
              <span>Add Trainees</span>
            </a>
          </div>
        </mat-expansion-panel>
      </a>
      <!--#endregion -->

      <!--#region Imtech  -->
      <a *ngIf="userRole=='IMT' || userRole=='IMTLCETB' && userType=='Admin'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/ActivityV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_activity.svg"> &nbsp; &nbsp; <span>Activity</span>
      </a>
      <!--#endregion -->

      <!-- #region Trainee Nav-->
      <a *ngIf="userType=='User'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/TraineesDetailsV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp; &nbsp; <span>Progression</span>
      </a>
      <a *ngIf="userType=='User'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/MyDetails']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp; &nbsp; <span>My Details</span>
      </a>
      <!--#endregion -->

      <!--#region JAN  -->
      <!-- <a *ngIf="userRole=='JAN' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item>
              <img style="height: 23px; vertical-align:-0.1em;" src="assets/2.0/AVA_Course_Icon.svg"> &nbsp; &nbsp;  <span>Courses</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="large-white-font" mat-list-item>
              <span>Course 1</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='JAN'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/JAN']" [state]="{courseName: 'M1'}" [queryParams]="{courseName: 'M1'}" queryParamsHandling="merge">
            <span>Module 1</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='JAN'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/JAN']" [state]="{courseName: 'M2'}" [queryParams]="{courseName: 'M2'}" queryParamsHandling="merge">
            <span>Module 2</span>
            </a>
          </div>
        </mat-expansion-panel>
        </mat-expansion-panel>
      </a> -->
      <!-- <a *ngIf="userRole=='GRE' && userType=='Admin'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/ActivityV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_activity.svg"> &nbsp; &nbsp; <span>Activity</span>
      </a> -->
      <a *ngIf="userRole=='JAN' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel  style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
        (mouseleave)="panel.close()" hideToggle="true" [expanded]="true" [disabled]="true">
          <mat-expansion-panel-header  style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: false}" [queryParams]="{openPopup: false}">
              <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp; &nbsp; <span>Trainees</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='JAN'">
            <a mat-list-item class="large-white-font" [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: true}" [queryParams]="{openPopup: true}">
              <span>Add Trainees</span>
            </a>
          </div>
        </mat-expansion-panel>
      </a>
      <!--#endregion -->

      <!--#region DePuy  -->
      <!-- <a *ngIf="userRole=='JAN' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item>
              <img style="height: 23px; vertical-align:-0.1em;" src="assets/2.0/AVA_Course_Icon.svg"> &nbsp; &nbsp;  <span>Courses</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <mat-expansion-panel style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
          (mouseleave)="panel.close()" hideToggle="true">
          <mat-expansion-panel-header style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="large-white-font" mat-list-item>
              <span>Course 1</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='JAN'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/JAN']" [state]="{courseName: 'M1'}" [queryParams]="{courseName: 'M1'}" queryParamsHandling="merge">
            <span>Module 1</span>
            </a>
          </div>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='JAN'">
            <a mat-list-item class="medium-white-font" [routerLink]="[ '/CoursesV2/JAN']" [state]="{courseName: 'M2'}" [queryParams]="{courseName: 'M2'}" queryParamsHandling="merge">
            <span>Module 2</span>
            </a>
          </div>
        </mat-expansion-panel>
        </mat-expansion-panel>
      </a> -->
      <!-- <a *ngIf="userRole=='GRE' && userType=='Admin'"  routerLinkActive="active-link" class="white-font" mat-list-item [routerLink]="['/V2/ActivityV2']">
        <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_activity.svg"> &nbsp; &nbsp; <span>Activity</span>
      </a> -->
      <a *ngIf="userRole=='DEP' && userType=='Admin'" style="background-color: #021B36;">
        <mat-expansion-panel  style="background-color: #021B36; padding: 0 0 0 0;" #panel (mouseenter)="panel.open()"
        (mouseleave)="panel.close()" hideToggle="true" [expanded]="true" [disabled]="true">
          <mat-expansion-panel-header  style="padding: 0 0;" routerLinkActive="active-link">
            <a mat-list-item class="white-font" mat-list-item [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: false}" [queryParams]="{openPopup: false}">
              <img style="height: 23px; vertical-align:-0.1em;" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp; &nbsp; <span>Trainees</span> &nbsp; <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </mat-expansion-panel-header>
          <div routerLinkActive="active-link-dropdown" *ngIf="userRole=='DEP'">
            <a mat-list-item class="large-white-font" [routerLink]="['/V2/TraineesListV2']" [state]="{openPopup: true}" [queryParams]="{openPopup: true}">
              <span>Add Trainees</span>
            </a>
          </div>
        </mat-expansion-panel>
      </a>
      <!--#endregion -->

      <div style="position: absolute; bottom: 0; width:100%;"> 
        <a style="background-color: #323233;" class="white-font" mat-list-item (click)="openPrivacy()">
          <span>Privacy</span>
        </a>
        <br style="display: block; content:''; margin-top: 1px;">
        <a style="background-color: #323233;" class="white-font" mat-list-item (click)="openPrivacy()">
          <span>Legal</span>
        </a>
        <a class="copyright-font">
          <mat-icon style="vertical-align:-0.25em;">copyright</mat-icon>&nbsp;<span (click)="openMersus()">Mersus Technologies</span>
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="nav-line">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon class="white-font" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="right-spacer"></span>
      <span class="white-font">{{userName.split('@')[0]}}</span>
      <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon"
        [matMenuTriggerFor]="menu" class="white-font">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item routerLink="/UpdatePassword">Change Password</button> -->
        <button mat-menu-item routerLink="/Logout">Logout</button>
      </mat-menu>
    </mat-toolbar>
    <!-- Add Content Here -->
    <div class="main-back">
      <ng-content></ng-content>        
    </div>
    <!-- End Content -->
  </mat-sidenav-content>
</mat-sidenav-container>
