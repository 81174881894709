import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-trainees-list-v2',
  templateUrl: './trainees-list-v2.component.html',
  styleUrls: ['./trainees-list-v2.component.scss']
})
export class TraineesListV2Component implements OnInit {
  dataSource= new MatTableDataSource();
  displayedColumnsVIS = ['Name', 'Module', 'Sessions', 'Duration', 'Date']
  displayedColumnsBOS = ['Name', 'Module', 'Sessions', 'Duration', 'Date', /*'emptyColumn',*/ 'Operations']
  search : String ="";
  tempData=[];
  userRole: any;
  userType: any;
  isLoading = true;

  selectedValue:string;
  showAddTraineePanel = false;
  fadeOut = false;
  selectedCourse;
  inviteSent=false;
  existsError=false;
  trainee;

  userToArchive;
  userToUnarchive;
  userToDelete;
  userToEdit;
  rowID;
  fadeButton = false;
  fadeSaveButton = true;
  nameUpdated = false; 
  nameToUpdate;
  errorToShow;
  showAddTraineePopup = false;
  activeUsers;
  inactiveUsers;
  pendingUsers;
  removedUsers;
  placeholderTexts = {
    'BOS': 'Line Clearance',
    'FPT': 'Injection Moulding',
    'RA': 'Introduction to Robotics Arm',
    'AVA': 'Introduction',
    'JAN': 'Liner Changeout',
    'DEP': 'Powder Coating'
  };
  
  constructor(
    private router: Router,
    private _httpService: HttpService,
    private formBuilder: UntypedFormBuilder) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      };
      if(this.router.getCurrentNavigation().extras.state){
        this.showAddTraineePopup = this.router.getCurrentNavigation().extras.state.openPopup; 
      }
      if(this.showAddTraineePopup) this.dropdownOptions('add');
     }

@ViewChild(MatSort, { static: true }) sort: MatSort;
@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

ngOnInit() {
  this._httpService.getAuthentication()
  .subscribe(data =>{
    this.userType = data.userType;
    this.userRole = data.role;
    if(this.userType == 'Admin'){
    if(this.userRole == 'VIS'){
      this._httpService.getTraineesList('VIS2').subscribe(data=>{
        for (let key in data) {
          if(!key.includes('Quiz'))
            this.tempData.push(data[key]);
      }
      this.sortRows('Name', this.tempData);
      this.isLoading = false;
      
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.tempData;
      }, error =>{
        this.isLoading = false;
        //console.log("Trainees Table  Error", error);
      }) 
    }
    if(this.userRole == 'BOS'){
      this.selectedCourse = "LineClearance";
      this._httpService.getTraineesCount('BOS3').subscribe(data=>{
        this.activeUsers = data.active;
        this.inactiveUsers = data.inactive;
        this.pendingUsers = data.invited;
        this.removedUsers = data.deleted;

      })
      this._httpService.getTraineesList('BOS3').subscribe(data=>{
        for (let key in data) {
          if(!key.includes('Quiz'))
            this.tempData.push(data[key]);
      }
      
      this.sortRows('Status', this.tempData);
      this.isLoading = false;
      
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.tempData;
      }, error =>{
        this.isLoading = false;
        //console.log("Trainees Table  Error", error);
      }) 
    }  
    if(this.userRole == 'FPT'){
      this.selectedCourse = "InjectionMoulding";
      this._httpService.getTraineesCount('FPT').subscribe(data=>{
        this.activeUsers = data.active;
        this.inactiveUsers = data.inactive;
        this.pendingUsers = data.invited;
        this.removedUsers = data.deleted;

      })
      this._httpService.getTraineesList('FPT').subscribe(data=>{
        for (let key in data) {
          if(!key.includes('Quiz'))
            this.tempData.push(data[key]);
      }
      
      this.sortRows('Status', this.tempData);
      this.isLoading = false;
      
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.tempData;
      }, error =>{
        this.isLoading = false;
        //console.log("Trainees Table  Error", error);
      }) 
    }
    if(this.userRole == 'AVA'){
      this.selectedCourse = "Introduction";
      this._httpService.getTraineesCount('AvaDemo').subscribe(data=>{
        this.activeUsers = data.active;
        this.inactiveUsers = data.inactive;
        this.pendingUsers = data.invited;
        this.removedUsers = data.deleted;

      })
      this._httpService.getTraineesList('AvaDemo').subscribe(data=>{
        for (let key in data) {
          if(!key.includes('Quiz'))
            this.tempData.push(data[key]);
      }
      
      this.sortRows('Status', this.tempData);
      this.isLoading = false;
      
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.tempData;
      }, error =>{
        this.isLoading = false;
        //console.log("Trainees Table  Error", error);
      }) 
    }
    if(this.userRole == 'JAN'){
      this.selectedCourse = "Liner Changeout";
      this._httpService.getTraineesCount('JAN').subscribe(data=>{
        this.activeUsers = data.active;
        this.inactiveUsers = data.inactive;
        this.pendingUsers = data.invited;
        this.removedUsers = data.deleted;

      })
      this._httpService.getTraineesList('JAN').subscribe(data=>{
        for (let key in data) {
          if(!key.includes('Quiz'))
            this.tempData.push(data[key]);
      }
      
      this.sortRows('Status', this.tempData);
      this.isLoading = false;
      
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.tempData;
      }, error =>{
        this.isLoading = false;
        //console.log("Trainees Table  Error", error);
      }) 
    }
    if(this.userRole == 'DEP'){
      this.selectedCourse = "Powder Coating";
      this._httpService.getTraineesCount('DEP').subscribe(data=>{
        this.activeUsers = data.active;
        this.inactiveUsers = data.inactive;
        this.pendingUsers = data.invited;
        this.removedUsers = data.deleted;

      })
      this._httpService.getTraineesList('DEP').subscribe(data=>{
        for (let key in data) {
          if(!key.includes('Quiz'))
            this.tempData.push(data[key]);
      }
      
      this.sortRows('Status', this.tempData);
      this.isLoading = false;
      
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.tempData;
      }, error =>{
        this.isLoading = false;
        //console.log("Trainees Table  Error", error);
      }) 
    }
    if(this.userRole == 'RA'){
      this.selectedCourse = "Introduction";
      this._httpService.getTraineesCount('RA').subscribe(data=>{
        this.activeUsers = data.active;
        this.inactiveUsers = data.inactive;
        this.pendingUsers = data.invited;
        this.removedUsers = data.deleted;

      })
      this._httpService.getTraineesList('RA').subscribe(data=>{
        for (let key in data) {
          if(!key.includes('Quiz'))
            this.tempData.push(data[key]);
      }
      
      this.sortRows('Status', this.tempData);
      this.isLoading = false;
      
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = this.tempData;
      }, error =>{
        this.isLoading = false;
        //console.log("Trainees Table  Error", error);
      }) 
    }    
    }
    }, error=>{
      if(error.status == 401){
        this.router.navigateByUrl("/Logout", { state: { error: '401' }});
      }
    })    
  }

  sortRows(prop, arr) {
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (a, b) {
        var i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        return a > b ? 1 : a < b ? -1 : 0;
    });
    return arr;
};

  searchTable(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showDetails(userData) {
    this.router.navigateByUrl("V2/TraineesDetailsV2", { state: {name: userData.Name, userID: userData.User_ID, status:userData.Status}});
  }

  dropdownOptions(selection){
    this.selectedValue = selection;
    if(this.selectedValue == 'add'){
      this.showAddTraineePanel = true;
      this.fadeOut = true;
      if(this.userRole == 'BOS') this.selectedCourse = "LineClearance";
      if(this.userRole == 'FPT') this.selectedCourse = "InjectionMoulding";
    }
  }

  closePopup(){
    this.showAddTraineePanel = false;
    this.fadeOut = false;
    this.selectedValue = '';
    //this.selectedCourse = '';
    this.inviteSent=false;
    this.existsError = false;
    this.personalForm.reset();
  }

  public personalForm = this.formBuilder.group({
    email : ['', Validators.compose([Validators.required, Validators.email])],
    fname : ['', Validators.compose([Validators.required])],
    lname : ['', Validators.compose([Validators.required])]
    }, {
  });

  selectCourse(course){
    this.selectedCourse = course; 
  }

  addTrainee(){
  this.trainee = {
    "email" : this.personalForm.controls['email'].value, 
    "fname" : this.personalForm.controls['fname'].value,
    "lname" : this.personalForm.controls['lname'].value,
    "registerTime" : new Date().toLocaleString('en-GB', { hour12: false }).replace(',',''),
    "orgName" : this.userRole,
    "course" : this.selectedCourse
  }
  this._httpService.addTrainee(this.trainee)
      .subscribe(data=>{
        this.inviteSent = true;
        window.location.reload();
      }, error => {
        this.existsError = true;
        if(error = 'User Exist'){
          this.errorToShow = "The email address already exists.";
        }
        else{
          this.errorToShow = "Something went wrong. Please try again after sometime.";
        }
        // console.log("Add Trainees Error", error);
      })
  this.inviteSent=false;
  this.existsError = false;
  }

  onNameChange(name){
    this.nameUpdated = true;
    this.nameToUpdate = name;
  }

  makeEditable(rowDetails){
    this.rowID = rowDetails.User_ID;
    this.fadeButton = true;
    this.fadeSaveButton = false;
  }

  editRow(rowDetails){
    if(this.nameToUpdate == undefined){
      this.fadeButton = false;
      this.rowID='';
      return;
    }
    else if(this.nameToUpdate != undefined){
      this.userToEdit ={
        "userID" : rowDetails.User_ID,
        "fname" : this.nameToUpdate.trim().split(' ')[0],
        "lname" : this.nameToUpdate.trim().split(' ')[1],
        "org" : this.userRole,
        "toUpdate" : 'name'
      }
    }
    this._httpService.editTrainee(this.userToEdit)
        .subscribe(data=>{
          Swal.fire({
            title: 'Trainee details updated!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#021B36'
          }).then(()=>{
            this.fadeButton = false;
            this.fadeSaveButton = true;
            window.location.reload();
          });
        }, error => {
          // console.log("Edit Trainee Error", error);
        })
  }

  deleteRow(rowDetails){
    this.fadeButton = true;
    this.userToDelete ={
      "userID" : rowDetails.User_ID,
      "org" : this.userRole
    }
    Swal.fire({
      title: 'Are you sure you want to delete this trainee?',
      text: 'You will not be able to revert this action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this._httpService.deleteTrainee(this.userToDelete)
        .subscribe(data=>{
          if(data == 'Success'){
            Swal.fire({
              title: 'Trainee deleted!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            }).then(() => {
              window.location.reload();
            });
          }
          else if(data == "User doesn't exist"){
            Swal.fire({
              title: "User doesn't exist",
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            });
          }
          else if(data == "User active"){
            Swal.fire({
              title: "Active users can't be directly deleted. Please archive the user first.",
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            }).then(() => {
              window.location.reload();
            });;
          }
        }, error => {
          // console.log("Delete Trainee Error", error);
        })
      }
      this.fadeButton = false;
      this.fadeSaveButton = true;
    });
  }

  archiveRow(rowDetails){
    this.fadeButton = true;
    this.userToArchive ={
      "userID" : rowDetails.User_ID,
      "org" : this.userRole
    }
    Swal.fire({
      title: 'Are you sure you want to archive this trainee?',
      text: 'You can revert this action.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this._httpService.archiveTrainee(this.userToArchive)
        .subscribe(data=>{
          if(data == 'Success'){
            Swal.fire({
              title: 'Trainee archived!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            }).then(() => {
              window.location.reload();
            });
          }
          else if(data == 'User is already inactive'){
            Swal.fire({
              title: 'User is already archived',
              icon: 'info',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            });
          }
          else if(data == "User doesn't exist"){
            Swal.fire({
              title: "User doesn't exist",
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            });
          }
        }, error => {
          // console.log("Archive Trainee Error", error);
        })
      }
      this.fadeButton = false;
      this.fadeSaveButton = true;
    });
  }

  unarchiveRow(rowDetails){
    this.fadeButton = true;
    this.userToUnarchive ={
      "userID" : rowDetails.User_ID,
      "org" : this.userRole
    }
    Swal.fire({
      title: 'Are you sure you want to unarchive this trainee?',
      text: 'You can revert this action.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this._httpService.unarchiveTrainee(this.userToUnarchive)
        .subscribe(data=>{
          if(data == 'Success'){
            Swal.fire({
              title: 'Trainee unarchived!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            }).then(() => {
              window.location.reload();
            });
          }
          else if(data == 'User is already active'){
            Swal.fire({
              title: 'User is already unarchived',
              icon: 'info',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            });
          }
          else if(data == "User doesn't exist"){
            Swal.fire({
              title: "User doesn't exist",
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            });
          }
        }, error => {
          // console.log("Archive Trainee Error", error);
        })
      }
      this.fadeButton = false;
      this.fadeSaveButton = true;
    });
  }
}
