<app-main-nav>
    <span class="text-config">My Details</span>
    <div fxLayout="row" class="container" fxLayoutAlign="space-around" fxLayout.lt-lg="column" [ngClass.lt-lg]="'container-col'" fxLayoutGap.lt-lg="5%">
        <div fxLayout="column" class="left-container" [ngClass.lt-lg]="'left-container-col'" fxFlex="50" fxFlex.lt-lg="90">
            <div>
                <span class="med-text-config">Change Password</span>
                <form [formGroup]="passwordForm" validate fxLayout="column">
                    <mat-form-field floatLabel="never">
                        <mat-label class="label-field"> Current Password </mat-label>
                        <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap>
                          <input class="input-text" matInput type="password" formControlName="pwd" [type]="hidepwd ? 'password' : 'text'">
                          <mat-icon matSuffix (click)="hidepwd = !hidepwd" style="margin-left:30%; vertical-align:middle; padding: 2% 2% 0% 0.5%; transform: scale(1.5);">
                            {{hidepwd ? 'visibility_off' : 'visibility'}}
                          </mat-icon>
                        </div>
                      </mat-form-field>
                      <span *ngIf="passwordForm.controls.pwd.touched && passwordForm.controls.pwd.hasError('required')" class="error-message">Password is required.</span>

                      <mat-form-field floatLabel="never" >
                        <mat-label class="label-field"> New Password</mat-label>
                        <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap>
                          <input class="input-text" matInput type="password" formControlName="newpwd" [type]="hidenewpwd ? 'password' : 'text'">
                          <mat-icon matSuffix (click)="hidenewpwd = !hidenewpwd" style="margin-left:30%; vertical-align:middle; padding: 2% 2% 0% 0.5%; transform: scale(1.5);">
                            {{hidenewpwd ? 'visibility_off' : 'visibility'}}
                          </mat-icon>
                        </div>
                      </mat-form-field>
                      <span *ngIf="passwordForm.controls.newpwd.touched && passwordForm.controls.newpwd.hasError('required')" class="error-message">New password is required.</span>
                      <span *ngIf="passwordForm.controls.newpwd.touched && passwordForm.controls.newpwd.hasError('minlength')" class="error-message">New password should be atleast 8 characters.</span>
                      <span *ngIf="passwordForm.controls.newpwd.touched && passwordForm.controls.newpwd.hasError('nums')" class="error-message">New password should contain atleast a numeric character.</span>
                      <span *ngIf="passwordForm.controls.newpwd.touched && passwordForm.controls.newpwd.hasError('lower')" class="error-message">New password should contain atleast a lowercase character.</span>
                      <span *ngIf="passwordForm.controls.newpwd.touched && passwordForm.controls.newpwd.hasError('upper')" class="error-message">New password should contain atleast a uppercase character.</span>
                      <span *ngIf="passwordForm.controls.newpwd.touched && passwordForm.controls.newpwd.hasError('special')" class="error-message">New password should contain atleast a special character.</span>
                      <span *ngIf = "passwordForm.controls.newpwd.touched && passwordForm.controls.newpwd.hasError('checkOldNewPasswordMatch')" class="error-message">New password should be different from your old password.</span>
                      <mat-form-field floatLabel="never" >
                        <mat-label class="label-field"> Confirm Password</mat-label>
                        <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap>
                          <input class="input-text" matInput type="password" formControlName="conpwd" [type]="hideconpwd ? 'password' : 'text'">
                          <mat-icon matSuffix (click)="hideconpwd = !hideconpwd" style="margin-left:30%; vertical-align:middle; padding: 2% 2% 0% 0.5%; transform: scale(1.5);">
                            {{hideconpwd ? 'visibility_off' : 'visibility'}}
                          </mat-icon>
                        </div>
                      </mat-form-field>

                      <span *ngIf="passwordForm.controls.conpwd.touched && passwordForm.controls.conpwd.hasError('required')" class="error-message">Confirm password is required.</span>
                      <span *ngIf = "passwordForm.controls.conpwd.hasError('confirmPasswordMatch')" class="error-message">Passwords should match.</span>

                </form>
            </div>
            <div>
                <span class="med-text-config">Change PIN</span>
                <form [formGroup]="pinForm" validate fxLayout="column">
                    <mat-form-field floatLabel="never" >
                        <mat-label class="label-field"> Current PIN </mat-label>
                        <div class="input-field" >
                          <input class="input-text" matInput type="password" formControlName="pin" [type]="hidepin ? 'password' : 'text'">
                          <mat-icon matSuffix (click)="hidepin = !hidepin" style="margin-left:30%; vertical-align:middle; transform: scale(1.5);">
                            {{hidepin ? 'visibility_off' : 'visibility'}}
                          </mat-icon>
                        </div>
                      </mat-form-field>
                      <span *ngIf="pinForm.controls.pin.touched && pinForm.controls.pin.hasError('required')" class="error-message">PIN is required.</span>

                      <mat-form-field floatLabel="never" >
                        <mat-label class="label-field"> New PIN</mat-label>
                        <div class="input-field" >
                          <input class="input-text" matInput type="password" formControlName="newpin" [type]="hidenewpin ? 'password' : 'text'">
                          <mat-icon matSuffix (click)="hidenewpin = !hidenewpin" style="margin-left:30%; vertical-align:middle; transform: scale(1.5);">
                            {{hidenewpin ? 'visibility_off' : 'visibility'}}
                          </mat-icon>
                        </div>
                      </mat-form-field>
                      <span *ngIf="pinForm.controls.newpin.touched && pinForm.controls.newpin.hasError('required')" class="error-message">New PIN is required.</span>
                      <span *ngIf="pinForm.controls.newpin.touched && pinForm.controls.newpin.hasError('minlength')" class="error-message">New PIN should contain atleast 4 numeric characters.</span>
                      <span *ngIf="pinForm.controls.newpin.touched && pinForm.controls.newpin.hasError('nums')" class="error-message">New PIN can only contain numeric characters.</span>
                      <span *ngIf = "pinForm.controls.newpin.touched && pinForm.controls.newpin.hasError('checkOldNewPINMatch')" class="error-message">New PIN should be different from your old PIN.</span>

                      <mat-form-field floatLabel="never" >
                        <mat-label class="label-field"> Confirm PIN</mat-label>
                        <div class="input-field" >
                          <input class="input-text" matInput type="password" formControlName="conpin" [type]="hideconpin ? 'password' : 'text'">
                          <mat-icon matSuffix (click)="hideconpin = !hideconpin" style="margin-left:30%; vertical-align:middle; transform: scale(1.5);">
                            {{hideconpin ? 'visibility_off' : 'visibility'}}
                          </mat-icon>
                        </div>
                      </mat-form-field>

                      <span *ngIf="pinForm.controls.conpin.touched && pinForm.controls.conpin.hasError('required')" class="error-message">Confirm PIN is required.</span>
                      <span *ngIf = "pinForm.controls.conpin.hasError('confirmPINMatch')" class="error-message">PINs should match.</span>

                </form>
            </div>
        </div>
        <div fxFlex="50" class="right-container" fxFlex.lt-lg="90">
            <span class="med-text-config">Personal Information</span><br><br>
            <form [formGroup]="personalForm" validate fxLayout="column">
                <mat-label class="non-floating-label"> Name </mat-label>
                <mat-form-field floatLabel="always">
                    <div class="input-field pointer-events" style="background-color:#021B36; color:white;">
                      <input class="input-text" matInput type="text" value="{{defaultName}}" readonly="readonly">
                    </div>
                  </mat-form-field>
                  <br>

                  <mat-label class="non-floating-label"> Username</mat-label>
                  <mat-form-field floatLabel="always" >
                    <div class="input-field" >
                      <input class="input-text" matInput type="text" formControlName="username">
                    </div>
                  </mat-form-field>
                  <br>

                  <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8%">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <mat-label class="non-floating-label">Gender</mat-label>
                        <mat-form-field floatLabel="always" appearance="fill" fxFlex="40" class="dropdown">
                          <mat-select [(value)]="selectedGender" formControlName="gender">
                            <mat-option value="Male">Male</mat-option>
                            <mat-option value="Female">Female</mat-option>
                            <mat-option value="NA">Not Specified</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column" fxLayoutGap="5%">
                        <mat-label class="non-floating-label">Age Range</mat-label>
                        <mat-form-field floatLabel="always" appearance="fill" fxFlex="40" class="dropdown">
                          <mat-select [(value)]="selectedAge" formControlName="age">
                            <mat-option value="NA">Not Specified</mat-option>
                            <mat-option value="18-24">18-24</mat-option>
                            <mat-option value="25-29">25-29</mat-option>
                            <mat-option value="30-34">30-34</mat-option>
                            <mat-option value="35-39">35-39</mat-option>
                            <mat-option value="40-44">40-44</mat-option>
                            <mat-option value="45-49">45-49</mat-option>
                            <mat-option value="50-54">50-54</mat-option>
                            <mat-option value="55-59">55-59</mat-option>
                            <mat-option value="60-64">60-64</mat-option>
                            <mat-option value="65-69">65-69</mat-option>
                            <mat-option value="70-74">70-74</mat-option>
                            <mat-option value="75-79">75-79</mat-option>
                            <mat-option value="80-84">80-84</mat-option>
                            <mat-option value="85-89">85-89</mat-option>
                            <mat-option value="90-94">90-94</mat-option>
                          </mat-select>
                        </mat-form-field>

                    </div>
                  </div>
            </form>
            <br><br>

            <div fxLayout="column" fxLayoutGap="30px">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <button class="button" (click)="cancel()" mat-raised-button type="button" fxFlex="30">
                  <span class="font-Libre-Medium">Cancel</span>
                </button>

                <button class="button" (click)="update()" mat-raised-button type="submit" fxFlex="30"
                [disabled]="(this.passwordForm.touched && this.passwordForm.invalid) ||
                            (this.pinForm.touched && this.pinForm.invalid) ||
                            (this.personalForm.touched && this.personalForm.invalid) ||
                            (!this.passwordForm.touched && !this.pinForm.touched && !this.personalForm.touched) ||
                            (!this.passwordForm.dirty && !this.pinForm.dirty && !this.personalForm.dirty)">
                  <span class="font-Libre-Medium">Save</span>
                </button>
              </div>
              <div fxLayout="row" *ngIf="formSubmitted && !noChanges && !formError">
                <img src="/assets/2.0/AVA_tick_icon.svg" fxFlex="10%" style="height: 20px;">
                <span class="small-text-config"> Changes made to the
                  <span *ngFor="let item of changesMadeTo; last as isLast;">
                    {{ item }}<span *ngIf="!isLast">, </span>
                  </span>
                </span>
              </div>
              <div fxLayout="row" *ngIf="formError && !noChanges && !formSubmitted">
                <img src="/assets/2.0/AVA_close_icon.svg" fxFlex="10%" style="height: 20px;">
                <span class="small-text-config"> {{errorMessage}}
                </span>
              </div>
              <div fxLayout="row" *ngIf="noChanges && !formError && !formSubmitted">
                <span class="small-text-config"> No changes were made to the form!
                </span>
              </div>
            </div>
        </div>
    </div>
</app-main-nav>
