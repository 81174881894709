import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
geoChart;
geoData;

totalSessions;
sessionsUpdate;
totalGuests;
guestUpdate;
totalTime;
timeUpdate;
guestTime;
guestTimeUpdate;


avgTime;
avgTimeUpdate;
users;
usersUpdate; 
location: [];
userRole;
userType;
isSessionLoading = true;
isUserLoading = true;

showAddAdminPanel = false;
viewAdminPanel = false;
selectedValue:string;
fadeOut = false;
inviteSent=false;
existsError=false;
displayedColumns = ['Name', 'Email', 'Operations'];
dataSource;

admin;
clientName;

userToDelete;
userToEdit;
rowID;
fadeButton = false;
fadeSaveButton = true;
nameUpdated = false; 
emailUpdated = false;
nameToUpdate;
emailToUpdate;
errorToShow;

  constructor(
    private _httpService: HttpService,
    private _sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private router: Router) {
      if(this.router.getCurrentNavigation().extras.state){
        this.clientName = this.router.getCurrentNavigation().extras.state.clientName;
        localStorage.setItem('client', this.clientName);
      } 
     }
  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.clientName = localStorage.getItem('client');
    this._httpService.getAuthentication()
  .subscribe(data =>{
    this.userType = data.userType;
    this.userRole = data.role;
    if(this.userType == 'Superadmin'){
      if(this.userRole == 'Mersus'){
      this._httpService.getClientSessionAnalyticsV2({"clientName": this.clientName}).subscribe(data=>{
        this.totalSessions = data.totalSessions;
        this.sessionsUpdate = data.sessionsUpdate;
        this.totalGuests = data.totalGuests;
        this.guestUpdate = data.guestUpdate;
        this.totalTime = data.totalTime;
        this.timeUpdate = data.timeUpdate;
        this.guestTime = data.guestTime;
        this.guestTimeUpdate = data.guestTimeUpdate;
        this.isSessionLoading = false;
      }, error =>{
        this.isSessionLoading = false;
        //console.log("Client Session Analytics Error", error);
      })  
      this._httpService.getClientUserAnalyticsV2({"clientName": this.clientName}).subscribe(data=>{
        this.avgTime = data.avgTime;
        this.avgTimeUpdate = data.avgTimeUpdate;
        this.users = data.users;
        this.usersUpdate = data.usersUpdate;

        this.location = data.location;
        var labels = new Array();
        var barGraphValues = new Array();
        for (let key in this.location) {
          labels.push(key);
          barGraphValues.push(this.location[key]);
         }
         this.barChartConfigGeoLocation(labels,barGraphValues);
         this.isUserLoading = false;
      }, error =>{
        this.isUserLoading = false;
        //console.log("Client User Analytics Error", error);
      }) 
    } 
    }
  }, error=>{
    if(error.status == 401){
      this.router.navigateByUrl("/Logout", { state: { error: '401' }});
    }
  })
  }

  barChartConfigGeoLocation(graphLabels, values){
    this.geoChart = new Chart('geo-chart', {
      type: 'horizontalBar',
      data: {
        labels: graphLabels,
        datasets: [
          {
            backgroundColor: ["#007d6f", "#007d6f","#007d6f","#007d6f", "#007d6f"],
            maxBarThickness: 60,
            data: values
          }
        ]
      },
      options: {
        legend: { display: false },
        scales: {
          yAxes: [{
            gridLines: {
              display: false
              }
          }],
          xAxes: [{
            display: false,
            gridLines: {
              display: false
              }
          }]
        },
        maintainAspectRatio: false
      }
  });  
  }

  dropdownOptions(selection){
    this.selectedValue = selection;
    if(this.selectedValue == 'add'){
      this.showAddAdminPanel = true;
      this.viewAdminPanel = false;
      this.fadeOut = true;
    }
    if(this.selectedValue == 'view'){
      this._httpService.getAdminsTable({"client" : this.clientName}).subscribe(data=>{
        this.dataSource = new MatTableDataSource(data.adminData);
      }, error =>{
        //console.log("getAdminsTable Error", error);
      }) 

      this.viewAdminPanel = true;
      this.showAddAdminPanel = false;
      this.fadeOut = true;
    }
  }

  closePopup(){
    this.showAddAdminPanel = false;
    this.viewAdminPanel = false;
    this.fadeOut = false;
    this.inviteSent=false;
    this.selectedValue = '';
    this.rowID = '';
    this.fadeButton = false;
    this.fadeSaveButton = true;
    this.existsError = false;
    this.personalForm.reset();
  }

  public personalForm = this.formBuilder.group({
    email : ['', Validators.compose([Validators.required, Validators.email])],
    fname : ['', Validators.compose([Validators.required])],
    lname : ['', Validators.compose([Validators.required])]
    }, {
  });

  addAdmin(){
    this.admin = {
      "email" : this.personalForm.controls['email'].value, 
      "fname" : this.personalForm.controls['fname'].value,
      "lname" : this.personalForm.controls['lname'].value,
      "registerTime" : new Date().toLocaleString('en-GB', { hour12: false }).replace(',',''),
      "orgName" : this.clientName
    }
    this._httpService.addAdmin(this.admin)
    .subscribe(data=>{
      this.inviteSent = true;
    }, error => {
      this.existsError = true;
        if(error = 'User Exist'){
          this.errorToShow = "The email address already exists.";
        }
        else{
          this.errorToShow = "Something went wrong. Please try again after sometime.";
        }
      // console.log("Add Admin Error", error);
    })
    this.inviteSent=false;
    this.existsError = false;
  }

  deleteRow(rowDetails){
    this.userToDelete ={
      "userID" : rowDetails.userID
    }
    Swal.fire({
      title: 'Are you sure you want to delete this admin?',
      text: 'You will not be able to revert this action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this._httpService.deleteAdmin(this.userToDelete)
        .subscribe(data=>{
          if(data == 'Success'){
            Swal.fire({
              title: 'Admin deleted!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            }).then(() => {
              window.location.reload();
            });
          }
          else if(data == "User doesn't exist"){
            Swal.fire({
              title: "Admin doesn't exist",
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#021B36'
            });
          }
        }, error => {
          // console.log("Delete Admin Error", error);
        })
      }
    });
  }

  makeEditable(rowDetails){
    this.rowID = rowDetails.userID;
    this.fadeButton = true;
    this.fadeSaveButton = false;
  }
  
  onNameChange(name){
    this.nameUpdated = true;
    this.nameToUpdate = name;
  }

  onEmailChange(email){
    this.emailUpdated = true;
    this.emailToUpdate = email;
  }

  editRow(rowDetails){
    if(this.nameToUpdate == undefined && this.emailToUpdate == undefined){
      this.fadeButton = false;
      this.rowID='';
      return;
    }
    //The functionality exists but we aren't allowing updating email ID
    if(this.nameToUpdate != undefined && this.emailToUpdate != undefined){
      this.userToEdit ={
        "userID" : rowDetails.userID,
        "fname" : this.nameToUpdate.trim().split(' ')[0],
        "lname" : this.nameToUpdate.trim().split(' ')[1],
        "email" : this.emailToUpdate.trim(),
        "toUpdate" : 'both'
      }
  }
    else if(this.nameToUpdate == undefined && this.emailToUpdate != undefined){
      this.userToEdit ={
      "userID" : rowDetails.userID,
      "email" : this.emailToUpdate.trim(),
      "toUpdate" : 'email'
    }
  }
    else if(this.emailToUpdate == undefined && this.nameToUpdate != undefined){
      this.userToEdit ={
        "userID" : rowDetails.userID,
        "fname" : this.nameToUpdate.trim().split(' ')[0],
        "lname" : this.nameToUpdate.trim().split(' ')[1],
        "toUpdate" : 'name'
      }
    }
  
    this._httpService.editAdmin(this.userToEdit)
        .subscribe(data=>{
          Swal.fire({
            title: 'Admin details updated!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#021B36'
          }).then(()=>{
            this.closePopup();
          });
        }, error => {
          // console.log("Edit Admin Error", error);
        })
  }
}