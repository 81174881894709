import { Component, OnInit, HostListener, AfterViewInit} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import {HttpService} from '../service/http.service';
import { SharedService } from '../service/shared.service';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';

interface Client {
  value: string;
  viewValue: string;
}
const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit  {
  public date = new Date();
  showActiveLicense = false;
  showExpiredLicense = false;
  NotExpired = false;
  mobileScreen = false;
  fadeOut = false;
  mersusGraph;
  userRole;
  userType;
  lineChart;
  barChart;
  trainingOutcomes;
  totalSessions;
  showFilter = false;
  totalDuration = {
    "hh" : "00",
    "mm" : "00",
    "ss" : "00"
  };
  totalTime;
  location;
  dataSource;
  isLoading = true;
  currentMonth;
  userCount;
  username;
  errorsCaught = 0;

  displayedColumns = ['Trainee', 'Module', 'Date'];

  clients: Client[] = [
    {value: 'VIS', viewValue: 'VistaMed'},
    {value: 'BOS', viewValue: 'Boston'},
    {value: 'FPT', viewValue: 'First Polymer Training'},
    {value: 'RA', viewValue: 'Robotics Arm'},
    {value: 'JAN', viewValue: 'Janssen'},
    {value: 'DEP', viewValue: 'Depuy'},
    {value: 'IMT', viewValue: 'Imtech'},
    {value: 'IMTLCETB', viewValue: 'ImtechLCETB'},
    {value: 'Mersus', viewValue: 'All'}
  ];

  campaignOne = new UntypedFormGroup({
    start: new UntypedFormControl(new Date(year, month, 13)),
    end: new UntypedFormControl(new Date(year, month, 16)),
  });
  campaignTwo = new UntypedFormGroup({
    start: new UntypedFormControl(new Date(year, month, 15)),
    end: new UntypedFormControl(new Date(year, month, 19)),
  });

  months_of_year = [];
  from = '5';
  toMonth = (new Date(). getMonth() + 12).toString();
  dataforyear :any;
  sliceformonth = [];
  sliceforyear = [];
  selectedValue:string;
  dataformonth: any;
  dataforyear1:any;
  dataforyear2:any;
  dataforfilter: any;
  status : any;
  betterMonths = []
  betterMonth = "";
  betterMonthList = ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"];
  betterTempMonth = 0;
  betterValueCounter = 0;
  betterStartYear = 2022;
  filterdata = [];
  yearSinceStart;
  data :any;
  selectedClientVar;
  masterData;
originalGraphData;

  constructor(
    private router: Router,
    private _httpService: HttpService,
    private _sharedService: SharedService
  ) {
    if(this.router.getCurrentNavigation().extras.state){
    this.username = this.router.getCurrentNavigation().extras.state.user; 
    localStorage.setItem('username', this.username);
    }
   }
   ngOnInit() {
    this.selectedValue = 'Mersus';
    this.username = localStorage.getItem('username');
    this.setMonthsLabel();

    while(this.betterMonth != this.betterMonthList[this.date.getMonth()] + ' ' + this.date.getFullYear()){
      this.betterMonth = this.betterMonthList[this.betterTempMonth] + ' ' + this.betterStartYear;
      this.betterMonths.push({
        month: this.betterMonth , value: this.betterValueCounter + 1
      });
      this.betterTempMonth++; 
      this.betterValueCounter++;
      if(this.betterTempMonth >= 12){
        this.betterTempMonth = 0;
        this.betterStartYear +=1;
      }
    }
    if(window.innerWidth <= 575){
      this.mobileScreen = true;
    }
    
    this._httpService.getAuthentication()
    .subscribe(data =>{
      this.userRole = data.role;
      this.userType = data.userType;
      this.yearSinceStart = (this.date.getFullYear() - 2022) * 12;

      if(this.userRole == "VIS"){
        this._httpService.getDashboardData('VIS2').subscribe(data=>{
          this.lineGraphConfig(data.graphData);
          this.applyAlltimeFilter();
          this.totalSessions = data.totalSessions;
          this.trainingOutcomes = data.moduleCompletion;
          if(this.trainingOutcomes == '100'){
            this.trainingOutcomes = '100.0';
          }

          this.totalTime = data.totalTime;
          this.userCount = data.location;
          this.dataSource = new MatTableDataSource(data.recentTableData);
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard Error");
        })
        this._httpService.getLicenseData('VIS2')
        .subscribe(data =>{
          if(data < 0){
            this.status = 'License Expired'
            this.showExpiredLicense = true;
          }
          else if(data == 0 && data == 30 && data == 60){
            this.status = data;
            this.showActiveLicense = true;
          }
          else{
            this.NotExpired = true;
          }
      })
      }

      if(this.userRole == "Mersus"){
        this._httpService.getDashboardDataMersusV2()
        .subscribe(data=>{
          this.selectedClientVar = "Mersus";
          this.lineGraphConfigMersus(data.graphData);
          this.masterData = this.lineChart.data.datasets.map(dataset => [...dataset.data]);
          this.applyAlltimeFilter();
          this.mersusGraph = data.graphData;
          this.totalSessions = data.totalSessions;
          this.trainingOutcomes = data.moduleCompletion;
          if(this.trainingOutcomes == '100'){
            this.trainingOutcomes = '100.0';
          }

          this.totalTime = data.totalTime;
          this.userCount = data.location;
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard V2 Error");
        })
        this.NotExpired = true;
      }

      if(this.userRole == "BOS"){
        this._httpService.getDashboardData('BOS3').subscribe(data=>{
          this.lineGraphConfig(data.graphData);
          this.applyYearlyFilter();
          this.totalSessions = data.totalSessions;
          this.trainingOutcomes = data.moduleCompletion;
          if(this.trainingOutcomes == '100'){
            this.trainingOutcomes = '100.0';
          }

          this.totalTime = data.totalTime;
          //this.barChartConfigGeoLocation(data.location);
          this.userCount = data.location;
          this.dataSource = new MatTableDataSource(data.recentTableData);
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard Error");
        })
        this._httpService.getLicenseData('BOS3')
        .subscribe(data =>{
            if(data < 0){
              this.status = 'License Expired'
              this.showExpiredLicense = true;
            }
            else if(data == 0 && data == 30 && data == 60){
              this.status = data;
              this.showActiveLicense = true;
            }
            else{
              this.NotExpired = true;
            }
        })
      }

      if(this.userRole == "FPT"){
        this._httpService.getDashboardData('FPT').subscribe(data=>{
          this.lineGraphConfig(data.graphData);
          this.applyYearlyFilter();
          this.totalSessions = data.totalSessions;
          this.trainingOutcomes = data.moduleCompletion;
          if(this.trainingOutcomes == '100'){
            this.trainingOutcomes = '100.0';
          }

          this.totalTime = data.totalTime;
          this.userCount = data.location;
          this.dataSource = new MatTableDataSource(data.recentTableData);
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard Error");
        })
        this._httpService.getLicenseData('FPT')
        .subscribe(data =>{
            if(data < 0){
              this.status = 'License Expired'
              this.showExpiredLicense = true;
            }
            else if(data == 0 && data == 30 && data == 60){
              this.status = data;
              this.showActiveLicense = true;
            }
            else{
              this.NotExpired = true;
            }
        })
      }

      if(this.userRole == "RA"){
        this._httpService.getDashboardData('RA').subscribe(data=>{
          this.lineGraphConfig(data.graphData);
          this.applyYearlyFilter();
          this.totalSessions = data.totalSessions;
          this.trainingOutcomes = data.moduleCompletion;
          if(this.trainingOutcomes == '100'){
            this.trainingOutcomes = '100.0';
          }

          this.totalTime = data.totalTime;
          this.userCount = data.location;
          this.dataSource = new MatTableDataSource(data.recentTableData);
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard Error");
        })
        this._httpService.getLicenseData('RA')
        .subscribe(data =>{
            if(data < 0){
              this.status = 'License Expired'
              this.showExpiredLicense = true;
            }
            else if(data == 0 && data == 30 && data == 60){
              this.status = data;
              this.showActiveLicense = true;
            }
            else{
              this.NotExpired = true;
            }
        })
      }

      if(this.userRole == "AVA"){
        this._httpService.getDashboardData('AvaDemo').subscribe(data=>{
          this.lineGraphConfig(data.graphData);
          this.applyYearlyFilter();
          this.totalSessions = data.totalSessions;
          this.trainingOutcomes = data.moduleCompletion;
          if(this.trainingOutcomes == '100'){
            this.trainingOutcomes = '100.0';
          }
          this.errorsCaught = data.totalErrors;
          this.totalTime = data.totalTime;
          this.userCount = data.location;
          this.dataSource = new MatTableDataSource(data.recentTableData);
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard Error");
        })
        this._httpService.getLicenseData('AvaDemo')
        .subscribe(data =>{
            if(data < 0){
              this.status = 'License Expired'
              this.showExpiredLicense = true;
            }
            else if(data == 0 && data == 30 && data == 60){
              this.status = data;
              this.showActiveLicense = true;
            }
            else{
              this.NotExpired = true;
            }
        })
      }

      if(this.userRole == "JAN"){
        this._httpService.getDashboardData('JAN').subscribe(data=>{
          this.lineGraphConfig(data.graphData);
          this.applyYearlyFilter();
          this.totalSessions = data.totalSessions;
          this.trainingOutcomes = data.moduleCompletion;
          if(this.trainingOutcomes == '100'){
            this.trainingOutcomes = '100.0';
          }
          this.errorsCaught = data.totalErrors;
          this.totalTime = data.totalTime;
          this.userCount = data.location;
          this.dataSource = new MatTableDataSource(data.recentTableData);
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard Error");
        })
        this._httpService.getLicenseData('JAN')
        .subscribe(data =>{
            if(data < 0){
              this.status = 'License Expired'
              this.showExpiredLicense = true;
            }
            else if(data == 0 && data == 30 && data == 60){
              this.status = data;
              this.showActiveLicense = true;
            }
            else{
              this.NotExpired = true;
            }
        })
      }

      if(this.userRole == "DEP"){
        this._httpService.getDashboardData('DEP').subscribe(data=>{
          this.lineGraphConfig(data.graphData);
          this.applyYearlyFilter();
          this.totalSessions = data.totalSessions;
          this.trainingOutcomes = data.moduleCompletion;
          if(this.trainingOutcomes == '100'){
            this.trainingOutcomes = '100.0';
          }
          this.errorsCaught = data.totalErrors;
          this.totalTime = data.totalTime;
          this.userCount = data.location;
          this.dataSource = new MatTableDataSource(data.recentTableData);
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard Error");
        })
        this._httpService.getLicenseData('DEP')
        .subscribe(data =>{
            if(data < 0){
              this.status = 'License Expired'
              this.showExpiredLicense = true;
            }
            else if(data == 0 && data == 30 && data == 60){
              this.status = data;
              this.showActiveLicense = true;
            }
            else{
              this.NotExpired = true;
            }
        })
      }

      if(this.userRole == "IMT"){
        this._httpService.getDashboardData('IMT').subscribe(data=>{
          this.lineGraphConfig(data.graphData);
          this.applyYearlyFilter();
          this.totalSessions = data.totalSessions;
          this.totalTime = data.totalTime;
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard Error");
        })
        this._httpService.getLicenseData('IMT')
        .subscribe(data =>{
            if(data < 0){
              this.status = 'License Expired'
              this.showExpiredLicense = true;
            }
            else if(data == 0 && data == 30 && data == 60){
              this.status = data;
              this.showActiveLicense = true;
            }
            else{
              this.NotExpired = true;
            }
        })
      }

      if(this.userRole == "IMTLCETB"){
        this._httpService.getDashboardData('IMTLCETB').subscribe(data=>{
          this.lineGraphConfig(data.graphData);
          this.applyYearlyFilter();
          this.totalSessions = data.totalSessions;
          this.totalTime = data.totalTime;
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          // console.log("Dashboard Error");
        })
        this._httpService.getLicenseData('IMTLCETB')
        .subscribe(data =>{
            if(data < 0){
              this.status = 'License Expired'
              this.showExpiredLicense = true;
            }
            else if(data == 0 && data == 30 && data == 60){
              this.status = data;
              this.showActiveLicense = true;
            }
            else{
              this.NotExpired = true;
            }
        })
      }
    },
    error=>{
      if(error.status == 401){
        this.router.navigateByUrl("/Logout", { state: { error: '401' }});
      }
    })
  }

  lineGraphConfig(graphData) {
      const organization = this.userRole
      const canvas = <HTMLCanvasElement>document.getElementById('line-chart');
      const ctx = canvas.getContext('2d');
      const graph_label = ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"];
      const graph_value = graphData[0];

      const orgDetails = this.getOrganizationDetails(organization);
      this.lineChart = new Chart(ctx, {
          type: 'bar',
          data: {
              labels: graph_label,
              datasets: [{
                  data: graph_value,
                  label: orgDetails.fullName,
                  borderColor: orgDetails.color,
                  backgroundColor: orgDetails.color,
                  fill: false
              }]
          },
          options: {
              title: {
                  display: true,
              },
              scales: {
                  yAxes: [{
                      ticks: {
                          fontColor: "#273e5c",
                          beginAtZero: true,
                          fontFamily: "Libre Franklin"
                      }
                  }],
                  xAxes: [{
                      gridLines: {
                          display: false
                      },
                      ticks: {
                          fontColor: "#273e5c",
                          beginAtZero: true,
                          fontFamily: "Libre Franklin"
                      }
                  }]
              },
              legend: {
                  display: false,
                  labels: {
                      fontFamily: "Libre Franklin",
                      boxWidth: 20,
                      fontSize: 18
                  },
                  align: 'end'
              },
              maintainAspectRatio: false
          }
      });
      
      this.originalGraphData = this.lineChart.data.datasets.map(dataset => ({
        ...dataset,
        data: [...dataset.data]
      }));
  }

  getOrganizationDetails(organization) {
    const organizationDetails = {
        'RA': { fullName: 'Robotics Arm', color: "#004093" },
        'IMT': { fullName: 'Imtech', color: "#cd5858" },
        'IMTLCETB': { fullName: 'ImtechLCETB', color: "#0b81b7" },
        'VIS': { fullName: 'VistaMed Catheter Assembly Introduction', color: "#b70b0b" },
        'BOS': { fullName: 'Boston Line Clearance', color: "#004093" },
        'FPT': { fullName: 'First Polymer Training', color: "#acb70b" },
        'AVA': { fullName: 'AVA Demo', color: "#004093" },
        'JAN': { fullName: 'Janssen Liner Changeout', color: "#930046" },
        'DEP': { fullName: 'DePuy Powder Coating', color: "#018093" }
    };

    return organizationDetails[organization] || { fullName: 'Mersus', color: "#000000" };
  }

  lineGraphConfigMersus(graphData) {
    const canvas = <HTMLCanvasElement> document.getElementById('line-chart');
    const ctx = canvas.getContext('2d');
    const graph_label = ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"];
    //const graph_value = new Array(12).fill(0);
    const graph_value = graphData;

    this.lineChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: graph_label,
        datasets: [{
          data: graph_value[0],
          label: this.getOrganizationDetails('VIS').fullName,
          borderColor: this.getOrganizationDetails('VIS').color,
          backgroundColor: this.getOrganizationDetails('VIS').color,
          fill: false
        },
        {
          data: graph_value[1],
          label: this.getOrganizationDetails('BOS').fullName,
          borderColor: this.getOrganizationDetails('BOS').color,
          backgroundColor: this.getOrganizationDetails('BOS').color,
          fill: false
        },
        {
          data: graph_value[2],
          label: this.getOrganizationDetails('FPT').fullName,
          borderColor: this.getOrganizationDetails('FPT').color,
          backgroundColor: this.getOrganizationDetails('FPT').color,
          fill: false
        },
        {
          data: graph_value[3],
          label: this.getOrganizationDetails('RA').fullName,
          borderColor: this.getOrganizationDetails('RA').color,
          backgroundColor: this.getOrganizationDetails('RA').color,
          fill: false
        },
        {
          data: graph_value[4],
          label: this.getOrganizationDetails('JAN').fullName,
          borderColor: this.getOrganizationDetails('JAN').color,
          backgroundColor: this.getOrganizationDetails('JAN').color,
          fill: false
        },
        {
          data: graph_value[5],
          label: this.getOrganizationDetails('DEP').fullName,
          borderColor: this.getOrganizationDetails('DEP').color,
          backgroundColor: this.getOrganizationDetails('DEP').color,
          fill: false
        },
        {
          data: graph_value[6],
          label: this.getOrganizationDetails('IMT').fullName,
          borderColor: this.getOrganizationDetails('IMT').color,
          backgroundColor: this.getOrganizationDetails('IMT').color,
          fill: false
        },
        {
          data: graph_value[7],
          label: this.getOrganizationDetails('IMTLCETB').fullName,
          borderColor: this.getOrganizationDetails('IMTLCETB').color,
          backgroundColor: this.getOrganizationDetails('IMTLCETB').color,
          fill: false
        }
      ]
      },
      options: {
        responsive: true,
        legend: {
          display: false,
          labels: {
            fontFamily: "Libre Franklin",
            boxWidth: 20,
            fontSize: 18
          },
          align: 'end'
       },
        scales: {
          yAxes: [{
              ticks: {
                  fontColor: "#273e5c",
                  beginAtZero: true,
                  fontFamily: "Libre Franklin"
              },

          }],
          xAxes: [{
            gridLines: {
              display: false
              },
              ticks: {
                  fontColor: "#273e5c",
                  beginAtZero: true,
                  fontFamily: "Libre Franklin"
              }
          }]
        },
        maintainAspectRatio: false
      }
    });
  }

  // barChartConfigGeoLocation(locationData){
  //   this.location = locationData;
  //   var labels = new Array()
  //   var barGraphValues = new Array()

  //   for (let key in this.location) {
  //     labels.push(key);
  //     barGraphValues.push(this.location[key]);
  //   }

  //   this.barChart = new Chart('bar-chart-horizontal', {
  //     type: 'horizontalBar',
  //     data: {
  //       labels: labels,
  //       datasets: [
  //         {
  //           backgroundColor: ["#007d6f"],
  //           maxBarThickness: 60,
  //           data: barGraphValues
  //         }
  //       ]
  //     },
  //     options: {
  //       legend: { display: false },
  //       scales: {
  //         yAxes: [{
  //           gridLines: {
  //             display: false
  //             }
  //         }],
  //         xAxes: [{
  //           display: false,
  //           gridLines: {
  //             display: false
  //             }
  //         }]
  //       },
  //       maintainAspectRatio: false
  //     }
  // });
  // }

  filterClient(selectedClient){
    this.selectedClientVar = selectedClient;
    if(selectedClient == 'VIS'){
      //Update Graph
      this.lineChart.data.datasets = [{
        data: this.mersusGraph[0],
        label: this.getOrganizationDetails('VIS').fullName,
          borderColor: this.getOrganizationDetails('VIS').color,
          backgroundColor: this.getOrganizationDetails('VIS').color,
        fill: false
      }]
      //Update Dashboard details
      this._httpService.getDashboardData('VIS2').subscribe(data=>{
        this.totalSessions = data.totalSessions;
        this.userCount = data.location;
        this.errorsCaught = 0;
        this.trainingOutcomes = data.moduleCompletion;
        if(this.trainingOutcomes == '100'){
          this.trainingOutcomes = '100.0';
        }

        this.totalTime = data.totalTime;
        //this.barChartConfigGeoLocation(data.location);
      }, error => {
        // console.log("Dashboard Error");
      })
    }
    if(selectedClient == 'BOS'){
      //Update Graph
      this.lineChart.data.datasets = [{
        data: this.mersusGraph[1],
        label: this.getOrganizationDetails('BOS').fullName,
        borderColor: this.getOrganizationDetails('BOS').color,
        backgroundColor: this.getOrganizationDetails('BOS').color,
        fill: false
      }]
      //Update Dashboard details
      this._httpService.getDashboardData('BOS3').subscribe(data=>{
        this.totalSessions = data.totalSessions;
        this.userCount = data.location;
        this.errorsCaught = 0;
        this.trainingOutcomes = data.moduleCompletion;
        if(this.trainingOutcomes == '100'){
          this.trainingOutcomes = '100.0';
        }

        this.totalTime = data.totalTime;
        //this.barChartConfigGeoLocation(data.location);
      }, error => {
        // console.log("Dashboard Error");
      })
    }
    if(selectedClient == 'FPT'){
      //Update Graph
      this.lineChart.data.datasets = [{
        data: this.mersusGraph[2],
        label: this.getOrganizationDetails('FPT').fullName,
        borderColor: this.getOrganizationDetails('FPT').color,
        backgroundColor: this.getOrganizationDetails('FPT').color,
        fill: false
      }]
      //Update Dashboard details
      this._httpService.getDashboardData('FPT').subscribe(data=>{
        this.totalSessions = data.totalSessions;
        this.userCount = data.location;
        this.errorsCaught = 0;
        this.trainingOutcomes = data.moduleCompletion;
        if(this.trainingOutcomes == '100'){
          this.trainingOutcomes = '100.0';
        }

        this.totalTime = data.totalTime;
        //this.barChartConfigGeoLocation(data.location);
      }, error => {
        // console.log("Dashboard Error");
      })
    }
    if(selectedClient == 'RA'){
      //Update Graph
      this.lineChart.data.datasets = [{
        data: this.mersusGraph[3],
        label: this.getOrganizationDetails('RA').fullName,
        borderColor: this.getOrganizationDetails('RA').color,
        backgroundColor: this.getOrganizationDetails('RA').color,
        fill: false
      }]
      //Update Dashboard details
      this._httpService.getDashboardData('RA').subscribe(data=>{
        this.totalSessions = data.totalSessions;
        this.userCount = data.location;
        this.errorsCaught = 0;
        this.trainingOutcomes = data.moduleCompletion;
        if(this.trainingOutcomes == '100'){
          this.trainingOutcomes = '100.0';
        }

        this.totalTime = data.totalTime;
      }, error => {
        // console.log("Dashboard Error");
      })
    }
    if(selectedClient == 'JAN'){
      //Update Graph
      this.lineChart.data.datasets = [{
        data: this.mersusGraph[4],
        label: this.getOrganizationDetails('JAN').fullName,
        borderColor: this.getOrganizationDetails('JAN').color,
        backgroundColor: this.getOrganizationDetails('JAN').color,
        fill: false
      }]
      //Update Dashboard details
      this._httpService.getDashboardData('JAN').subscribe(data=>{
        this.totalSessions = data.totalSessions;
        this.errorsCaught = data.totalErrors;
        this.userCount = data.location;
        this.trainingOutcomes = data.moduleCompletion;
        if(this.trainingOutcomes == '100'){
          this.trainingOutcomes = '100.0';
        }

        this.totalTime = data.totalTime;
      }, error => {
        // console.log("Dashboard Error");
      })
    }
    if(selectedClient == 'DEP'){
      //Update Graph
      this.lineChart.data.datasets = [{
        data: this.mersusGraph[5],
        label: this.getOrganizationDetails('DEP').fullName,
          borderColor: this.getOrganizationDetails('DEP').color,
          backgroundColor: this.getOrganizationDetails('DEP').color,
        fill: false
      }]
      //Update Dashboard details
      this._httpService.getDashboardData('DEP').subscribe(data=>{
        this.totalSessions = data.totalSessions;
        this.errorsCaught = data.totalErrors;
        this.userCount = data.location;
        this.trainingOutcomes = data.moduleCompletion;
        if(this.trainingOutcomes == '100'){
          this.trainingOutcomes = '100.0';
        }

        this.totalTime = data.totalTime;
      }, error => {
        // console.log("Dashboard Error");
      })
    }
    if(selectedClient == 'IMT'){
      //Update Graph
      this.lineChart.data.datasets = [{
        data: this.mersusGraph[6],
        label: this.getOrganizationDetails('IMT').fullName,
          borderColor: this.getOrganizationDetails('IMT').color,
          backgroundColor: this.getOrganizationDetails('IMT').color,
        fill: false
      }]
      //Update Dashboard details
      this._httpService.getDashboardData('IMT').subscribe(data=>{
        this.totalSessions = data.totalSessions;
        this.errorsCaught = data.totalErrors;
        this.userCount = data.location;
        this.trainingOutcomes = data.moduleCompletion;
        if(this.trainingOutcomes == '100'){
          this.trainingOutcomes = '100.0';
        }

        this.totalTime = data.totalTime;
      }, error => {
        // console.log("Dashboard Error");
      })
    }
    if(selectedClient == 'IMTLCETB'){
      //Update Graph
      this.lineChart.data.datasets = [{
        data: this.mersusGraph[7],
        label: this.getOrganizationDetails('IMTLCETB').fullName,
          borderColor: this.getOrganizationDetails('IMTLCETB').color,
          backgroundColor: this.getOrganizationDetails('IMTLCETB').color,
        fill: false
      }]
      //Update Dashboard details
      this._httpService.getDashboardData('IMTLCETB').subscribe(data=>{
        this.totalSessions = data.totalSessions;
        this.errorsCaught = data.totalErrors;
        this.userCount = data.location;
        this.trainingOutcomes = data.moduleCompletion;
        if(this.trainingOutcomes == '100'){
          this.trainingOutcomes = '100.0';
        }

        this.totalTime = data.totalTime;
      }, error => {
        // console.log("Dashboard Error");
      })
    }
    if(selectedClient == 'Mersus'){
      //Update Graph
      this.lineChart.data.datasets = [{
        data: this.mersusGraph[0],
        label: this.getOrganizationDetails('VIS').fullName,
        borderColor: this.getOrganizationDetails('VIS').color,
        backgroundColor: this.getOrganizationDetails('VIS').color,
        fill: false
      },
      {
        data: this.mersusGraph[1],
        label: this.getOrganizationDetails('BOS').fullName,
        borderColor: this.getOrganizationDetails('BOS').color,
        backgroundColor: this.getOrganizationDetails('BOS').color,
        fill: false
      },
      {
        data: this.mersusGraph[2],
        label: this.getOrganizationDetails('FPT').fullName,
        borderColor: this.getOrganizationDetails('FPT').color,
        backgroundColor: this.getOrganizationDetails('FPT').color,
        fill: false
      },
      {
        data: this.mersusGraph[3],
        label: this.getOrganizationDetails('RA').fullName,
        borderColor: this.getOrganizationDetails('RA').color,
        backgroundColor: this.getOrganizationDetails('RA').color,
        fill: false
      },
      {
        data: this.mersusGraph[4],
        label: this.getOrganizationDetails('JAN').fullName,
        borderColor: this.getOrganizationDetails('JAN').color,
        backgroundColor: this.getOrganizationDetails('JAN').color,
        fill: false
      },
      {
        data: this.mersusGraph[5],
        label: this.getOrganizationDetails('DEP').fullName,
        borderColor: this.getOrganizationDetails('DEP').color,
        backgroundColor: this.getOrganizationDetails('DEP').color,
        fill: false
      }
    ]
      //Update Dashboard details
      this._httpService.getDashboardDataMersusV2()
        .subscribe(data=>{
          this.mersusGraph = data.graphData;
          this.totalSessions = data.totalSessions;
          this.errorsCaught = data.totalErrors;
          this.trainingOutcomes = data.moduleCompletion;
          this.userCount = data.location;
          if(this.trainingOutcomes == '100'){
            this.trainingOutcomes = '100.0';
          }
          this.totalTime = data.totalTime;
        }, error => {
          // console.log("Dashboard V2 Error");
        })
    }
    this.lineChart.update();
    this.applyAlltimeFilter();
  }

  showDetails(userData) {
    this.router.navigateByUrl("V2/TraineesDetailsV2", { state: {name: userData.Trainee, userID: userData.UserID}});
  }

  getfilterdta() {
    const selectedIndex = this.clients.findIndex(client => client.value === this.selectedClientVar);
    if (this.userRole === "Mersus") {
      if(this.selectedClientVar === "Mersus")
        this.filterdata = this.masterData.map(data => [...data]);//seems fine
      else
        this.filterdata.push(this.lineChart.data.datasets[selectedIndex].data);//not fine
}
    else{
      this.filterdata.push(this.lineChart.data.datasets[0].data);//seems fine
    }
    return this.filterdata;
  }
  
  applyDateFilter() {
    this.currentMonth = this.date.getMonth() + 1 + (this.date.getFullYear() - 2022) * 12;
    this.data = this.getfilterdta();
    this.lineChart.data.labels = this.months_of_year.slice(this.yearSinceStart + this.date.getMonth() - 2, this.yearSinceStart + this.date.getMonth() + 1);
    this.lineChart.data.datasets.forEach((dataset, index) => {
      const filteredData = this.data[index].slice(this.currentMonth - 3, this.currentMonth);
      this.lineChart.data.datasets[index].data = filteredData;
    });
    this.lineChart.update();
  }
 
  applyCurrentMonthFilter() {
    this.currentMonth = this.date.getMonth() + 1 + (this.date.getFullYear() - 2022) * 12;
    this.data = this.getfilterdta();
    this.lineChart.data.labels = this.months_of_year.slice(this.yearSinceStart + this.date.getMonth(), this.yearSinceStart + this.date.getMonth() + 1);
    this.lineChart.data.datasets.forEach((dataset, index) => {
      const filteredData = this.data[index].slice(this.currentMonth - 1, this.currentMonth);
      this.lineChart.data.datasets[index].data = filteredData;
    });
    this.lineChart.update();
  }

  applyYearlyFilter() {
    this.currentMonth = this.date.getMonth() + 1 + (this.date.getFullYear() - 2022) * 12;
    this.data = this.getfilterdta();
    this.lineChart.data.labels = this.months_of_year.slice(this.yearSinceStart + this.date.getMonth() - 11, this.yearSinceStart + this.date.getMonth() + 1);
    this.lineChart.data.datasets.forEach((dataset, index) => {
      const filteredData = this.data[index].slice(this.currentMonth - 12, this.currentMonth);
      this.lineChart.data.datasets[index].data = filteredData;
    });
  
    this.lineChart.update();
  }

  applyAlltimeFilter() {
    this.currentMonth = this.date.getMonth() + 1 + (this.date.getFullYear() - 2022) * 12;
    this.data = this.getfilterdta();
    this.lineChart.data.labels = this.months_of_year.slice(0, this.currentMonth);
  
    this.lineChart.data.datasets.forEach((dataset, index) => {
      const filteredData = this.data[index].slice();
      this.lineChart.data.datasets[index].data = filteredData;
    });
  
    this.lineChart.update();
  }
  
  applyDateFilter1() {
    this.data = this.getfilterdta();
    this.lineChart.data.labels = this.months_of_year.slice(parseInt(this.from) - 1, parseInt(this.toMonth) + 1);
  
    this.lineChart.data.datasets.forEach((dataset, index) => {
      const filteredData = this.data[index].slice(parseInt(this.from) - 1, parseInt(this.toMonth) + 1);
      this.lineChart.data.datasets[index].data = filteredData;
    });
  
    this.lineChart.update();
  }

  setMonthsLabel(){
   var localMonths = ['JAN ', 'FEB ', 'MAR ', 'APR ', 'MAY ', 'JUNE ', 'JULY ', 'AUG ', 'SEPT ', 'OCT ', 'NOV ', 'DEC ']
   for(var i = parseInt("2022"); i <= this.date.getFullYear(); i++){
     for(var j = 0; j < 12; j++){
       if(j > this.date.getMonth() && i == this.date.getFullYear()){
         break;
     }
       this.months_of_year.push(localMonths[j] + " " + i);
     }  
   }
  }

  hidepopup(){
    this.showActiveLicense = false;
    this.fadeOut =false;
  }
  
  backtologin(){
    this.router.navigateByUrl("Logout");
  }
}
