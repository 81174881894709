<app-main-nav>
  <div fxLayout="row" fxLayoutAlign="center" *ngIf="showAddTraineePanel" class="popup" [ngClass.lt-lg]="'popup-smallScreen'">
    <div fxLayout="column" fxLayoutGap="10%" fxFlex="99">
      <span class="med-heading-text-config" style="margin-right:auto;">Enter trainee details</span>
      <form [formGroup]="personalForm" (ngSubmit)="addTrainee()" validate fxLayout="column">
        <mat-label class="non-floating-label"> Email address </mat-label>
        <mat-form-field floatLabel="always">
            <div class="input-field">
              <input class="input-text" autofocus matInput type="text" formControlName="email">
            </div>
        </mat-form-field>
        <span *ngIf="personalForm.controls.email.touched && personalForm.controls.email.hasError('email')" class="error-message">Enter a valid email address.</span>
        <mat-label class="non-floating-label"> First Name </mat-label>
        <mat-form-field floatLabel="always">
            <div class="input-field">
              <input class="input-text" matInput type="text" formControlName="fname">
            </div>
        </mat-form-field>
        <mat-label class="non-floating-label"> Surname</mat-label>
        <mat-form-field floatLabel="always" >
          <div class="input-field" >
            <input class="input-text" matInput type="text" formControlName="lname">
          </div>
        </mat-form-field>
        <mat-label class="non-floating-label"> Training Courses</mat-label><br>
        <div fxLayout="row">
          <mat-form-field floatLabel="always" appearance="standard" class="course-dropdown" fxFlex="99">
            <mat-select [(value)]="selectedCourse" (selectionChange)="selectCourse($event.value)"
                       [placeholder]="placeholderTexts[userRole]">
            </mat-select>
          </mat-form-field>
          <mat-icon class="dropdown-arrow" fxFlex="1">keyboard_arrow_down</mat-icon>
        </div><br><br>
        <button class="button"  mat-raised-button type="submit" [disabled]="this.personalForm.invalid">SEND INVITATION</button>
      </form>
      <div fxLayout="row" *ngIf="inviteSent">
        <img src="/assets/2.0/AVA_tick_icon.svg" fxFlex="10%" style="height: 20px;">
        <span class="small-text-config"> Invitation sent to {{this.personalForm.controls['email'].value}}</span>
      </div>
      <span class="small-text-config" *ngIf="existsError"> {{errorToShow}} </span>
    </div>
    <img src="/assets/2.0/AVA_close_icon.svg" style="z-index: 1; height: 50px; position:relative; margin: -8% -20% 1% 5%; cursor:pointer;" (click)="closePopup()">
  </div>

  <div [style.opacity]="fadeOut?'.2':null" [ngClass]="fadeOut? 'main-container pointer-events': 'main-container'">
    <div fxLayout="row" fxLayout.lt-lg="column"  fxLayoutGap.lt-lg="5%" fxLayoutGap="85px" fxLayoutWrap>
      <div fxLayout="column" fxFlex="30">
        <div class="text-config">Trainees</div>
        <div class="med-text-config">Overview</div>
      </div>
      <div *ngIf="userRole=='BOS' || userRole=='FPT'|| userRole=='AVA'|| userRole=='RA'|| userRole=='JAN'|| userRole=='DEP'" 
      fxLayout="row" fxLayoutGap="8px"  fxFlex = "50" fxFlex.lt-lg="100"  fxLayoutAlign.lt-md="start" 
      style="min-width: 5%; max-width: 100%;">
        <mat-card class="card-config" fxFlex="33.3%" fxFlex.lt-lg="100%">
            <mat-card-title class="card-heading-config">
                <span>Active</span>
            </mat-card-title>
            <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
              <app-skeleton-loading Cwidth="240" Cheight="150">
              </app-skeleton-loading>
            </div>
            <mat-card-content class="card-content-config" style="text-align:center; color: #007d6f; font-weight: 600;">
                {{activeUsers}}
            </mat-card-content>
        </mat-card>
        <mat-card class="card-config" fxFlex="33.3%" fxFlex.lt-lg="100%">
          <mat-card-title class="card-heading-config">
              <span>Inactive</span>
          </mat-card-title>
          <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
            <app-skeleton-loading Cwidth="240" Cheight="150">
            </app-skeleton-loading>
          </div>
          <mat-card-content class="card-content-config" style="text-align:center;">
              {{inactiveUsers}}
          </mat-card-content>
        </mat-card>
        <mat-card class="card-config" fxFlex="33.3%" fxFlex.lt-lg="100%">
        <mat-card-title class="card-heading-config">
            <span>Pending</span>
        </mat-card-title>
        <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
          <app-skeleton-loading Cwidth="240" Cheight="150">
          </app-skeleton-loading>
        </div>
        <mat-card-content class="card-content-config" style="text-align:center;">
            {{pendingUsers}}
        </mat-card-content>
        </mat-card>
        <mat-card class="card-config" fxFlex="33.3%" fxFlex.lt-lg="100%">
      <mat-card-title class="card-heading-config">
          <span>Removed</span>
      </mat-card-title>
      <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
        <app-skeleton-loading Cwidth="240" Cheight="150">
        </app-skeleton-loading>
      </div>
      <mat-card-content class="card-content-config" style="text-align:center;">
          {{removedUsers}}
      </mat-card-content>
        </mat-card>
      </div>
      <div *ngIf="userRole=='BOS' || userRole=='FPT'|| userRole=='AVA'|| userRole=='RA'|| userRole=='JAN'|| userRole=='DEP'" 
      fxLayout="row"  fxFlex = "20" fxFlex.lt-lg="100"  fxLayoutAlign.lt-md="start" style="min-width: 5%; max-width: 93%;">
        <button fxFlex="97" class="dropdown" mat-raised-button type="button" (click)="dropdownOptions('add')">
          <mat-icon style="font-weight:800;">add</mat-icon> &nbsp; Add Trainee
        </button>
        <!-- <mat-form-field floatLabel="never" appearance="fill" fxFlex="50" class="dropdown">
          <mat-label style="font-family: 'Libre Franklin'; color:white;" [ngClass]="selectedValue?'hidden':''">ADD TRAINEES</mat-label>
          <mat-select [(value)]="selectedValue" (selectionChange)="dropdownOptions($event.value)">
            <mat-option value="add" [ngClass]="(selectedValue=='add')?'dropdown-selected-text':''">Add a new trainee</mat-option>
             <mat-option value="addBulk">Import from a CSV file...</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon class="custom-filter-config-arrow" fxFlex="1">keyboard_arrow_down</mat-icon> -->
      </div>
    </div>
    <div fxLayoutAlign="flex-start" fxLayout.lt-md="column" fxLayoutWrap class="search-bar-config">
        <mat-form-field style="min-width: 5%; max-width: 93%;">
          <input class="input-config" (keyup)="searchTable($event.target.value)" matInput>
          <mat-icon class="mat-icon-config" matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <div [ngClass]="fadeButton? 'table-config-edit': 'table-config'">
        <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="Name">
              <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell  style="border-left: 2px solid #707070;" class="table-data-font" *matCellDef="let row" [contentEditable]="rowID === row.User_ID" (input)="onNameChange($event.target.innerHTML)"> {{row.Name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Module">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Progress</mat-header-cell>
              <mat-cell class="table-data-font" *matCellDef="let row"> 
                <div *ngIf="row.Module == 100">
                  <img src="/assets/2.0/AVA_tick_icon.svg" style="height: 35px;">
                </div>
                <div *ngIf="row.Module != 100">
                  <circle-progress [percent] = "row.Module" [radius]="15" space="-3"
                  outerStrokeColor="#23865a" innerStrokeColor="#021B36" outerStrokeWidth="5" innerStrokeWidth="1" animationDuration="600" 
                  [showSubtitle]="false" [showBackground]="false" [showTitle] = "false" [showZeroOuterStroke]="false"
                  [showUnits]="false" 
                  ></circle-progress>
                </div>
              
              </mat-cell>
            </ng-container>

              <ng-container matColumnDef="Sessions">
                <mat-header-cell class="table-header-font" *matHeaderCellDef> Sessions </mat-header-cell>
                <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Sessions}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Duration">
                <mat-header-cell class="table-header-font" *matHeaderCellDef> 
                  <img src="/assets/icon/Timer_icon.svg" style="margin-left: 40px; height: 30px;"> 
                </mat-header-cell>
                <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Duration}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Date">
                <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header="latestTime"> 
                  <img src="/assets/icon/Date_Icon.svg" style="margin-left: 30px; height: 40px;"> 
                </mat-header-cell>
                <mat-cell style="border-right: 2px solid #707070;" class="table-data-font" *matCellDef="let row"> {{row.Date}} </mat-cell>
              </ng-container>

              <!-- <ng-container *ngIf="userRole!='VIS'" matColumnDef="emptyColumn">
                <mat-header-cell class="table-space" *matHeaderCellDef></mat-header-cell>
                <mat-cell  class="table-space" *matCellDef="let row"></mat-cell>
              </ng-container> -->

              <ng-container *ngIf="userRole!='VIS'" matColumnDef="Operations">
                <mat-header-cell class="table-header-font" *matHeaderCellDef> &nbsp;&nbsp;&nbsp;&nbsp; Operations </mat-header-cell>
                <mat-cell class="table-data-font-colored" *matCellDef="let row">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span [ngClass]="row.Status === 'Active' ? 'positive' : row.Status === 'Inactive' ? 'negative' : 'neutral'"><mat-icon>fiber_manual_record</mat-icon></span>
                
                  <button mat-button (click)="makeEditable(row);" *ngIf="!fadeButton"><mat-icon matTooltip="Edit">create</mat-icon></button>
                  <button mat-button (click)="editRow(row)" *ngIf="fadeButton"><mat-icon matTooltip="Save">done_outline</mat-icon></button>
                  <button *ngIf="row.Status=='Inactive'" mat-button (click)="unarchiveRow(row);"><mat-icon matTooltip="Unarchive">unarchive</mat-icon></button>
                  <button *ngIf="row.Status=='Inactive' || row.Status=='Invited'" mat-button (click)="deleteRow(row);" [disabled]="fadeButton"><mat-icon  matTooltip="Delete">delete</mat-icon></button>
                  <button *ngIf="row.Status=='Active'" mat-button (click)="archiveRow(row);" [disabled]="fadeButton"><mat-icon matTooltip="Archive">archive</mat-icon></button>
                </mat-cell>
              </ng-container>

            <mat-header-row class="table-header-font" *matHeaderRowDef="userRole=='VIS'?displayedColumnsVIS:displayedColumnsBOS"></mat-header-row>
            <mat-row *matRowDef="let row; columns: userRole=='VIS'?displayedColumnsVIS:displayedColumnsBOS;" (click)="fadeButton?$event.stopPropagation():showDetails(row)"></mat-row>
          </mat-table>
          <div *ngIf="isLoading" style="margin-left:0.5%; margin-top: 0.5%;">
            <div *ngFor="let _ of [].constructor(10)">
              <app-skeleton-loading Cwidth="1200" Cheight="48"><br><br>
              </app-skeleton-loading>
            </div>
          </div>
          <mat-paginator class="paginator-class" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</app-main-nav>
