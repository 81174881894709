import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponentV2 implements OnInit {
  mobileScreen = false;
  resetForm: UntypedFormGroup;
  resetEmail;
  traineeEmail;
  inviteSent=false;
  emailError=false;
  mode="";
  org="";
  userID=""

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private _httpService: HttpService,
    private _sharedService: SharedService
  ) { 
    if(this.router.getCurrentNavigation().extras.state){
      this.mode = this.router.getCurrentNavigation().extras.state.mode;
    } 
   }

  ngOnInit() {
    if(window.innerWidth <= 575){
      this.mobileScreen = true;
    }
    this.resetForm = this.createFormGroup();
  }

  createFormGroup(): UntypedFormGroup{
    return new UntypedFormGroup({
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.email
      ])
    })
  }

  reset(): void{
    if(this.mode == "forget"){
      this.resetEmail = {
        "email": this.resetForm.controls['email'].value
      }
      this._httpService.forgetPassword(this.resetEmail)
        .subscribe(data=>{
          this.inviteSent = true;
        }, error => {
          if(error='Invalid Login!')
          this.emailError = true;
        })
        this.inviteSent=false;
        this.emailError = false;
    }
    if(this.mode == "register"){
      this.traineeEmail = {
        "email": this.resetForm.controls['email'].value
      }
      this._httpService.webRegisterTrainee(this.traineeEmail)
        .subscribe(data=>{
          if(data != null){
            this.org = data.org;
            this.userID = data.userID;
            let registerURL = "V2/RegisterTrainee/" + this.org + "/" + this.userID;
            this.router.navigateByUrl(registerURL);
          }
          else{
            this.emailError = true;
          }
        }, error => {
          if(error='Invalid Login!')
          this.emailError = true;
        })
        this.emailError = false;
    }

    //this.router.navigateByUrl("V2/NewPassword");

    // this._httpService.getLogin(this.resetForm.value)
    //   .subscribe(data=>{
    //     this.snackBar.open('Email sent', 'Close', {
    //       duration: 3000,
    //       "horizontalPosition": "right",
    //       "verticalPosition": "bottom",
    //     });

    //   }, error =>{
    //     this.snackBar.open('Email not sent!', 'Close', {
    //       duration: 3000,
    //       "horizontalPosition": "right",
    //       "verticalPosition": "bottom",
    //     });
    //   })
  }
}
