import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {AdminDashboardComponent} from './v2/shared/admin-dashboard/admin-dashboard.component';
import {MainNavComponent} from './v2/shared/main-nav/main-nav.component';
import {LoginComponent} from './v2/shared/login-components/login/login.component';
import {LogoutComponent} from './v2/shared/login-components/logout/logout.component';
import {TraineesComponent} from './v1/trainees/trainees.component';
import {ChangePasswordComponent} from './v2/shared/change-password/change-password.component';
import { Vis001caveComponent } from './v1/courses/vis001cave/vis001cave.component';
import { Bos002Component } from './v1/courses/bos002/bos002.component';
import {VerifyEmailComponent} from './v2/shared/account-verify/verify-email/verify-email.component';
import {ForgotPasswordComponent} from './v2/shared/account-verify/forgot-password/forgot-password.component';

//V2
import {CoursesComponent} from './v2/trainer/courses-list-v2/courses.component';
import { Vis2Component } from './v2/trainer/courses-v2/vis2/vis2.component';
import { TraineesV2Component } from './v2/trainer/trainees-v2/trainees-v2.component';
import { TraineesDetailsV2Component } from './v2/trainer/trainees-details-v2/trainees-details-v2.component';
import { ClientsComponent } from './v2/superadmin/clients/clients.component';
import { NotFoundComponent } from './v2/shared/not-found/not-found.component';
import { TraineesListV2Component } from './v2/trainer/trainees-list-v2/trainees-list-v2.component';
import { ForgotPasswordComponentV2 } from './v2/shared/login-components/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './v2/shared/login-components/new-password/new-password.component';
import { TraineeRegistrationComponent } from './v2/trainee/trainee-registration/trainee-registration.component';
import { MyDetailsComponent } from './v2/trainee/my-details/my-details.component';
import { TrainerRegistrationComponent } from './v2/trainer/trainer-registration/trainer-registration.component';
import { BosComponent } from './v2/trainer/courses-v2/bos/bos.component';
import { FptComponent } from './v2/trainer/courses-v2/fpt/fpt.component';
import { GreComponent } from './v2/trainer/courses-v2/gre/gre.component';
import { JanComponent } from './v2/trainer/courses-v2/jan/jan.component';
import { DepComponent as DePuyComponent } from './v2/trainer/courses-v2/dep/dep.component';
import { RaComponent } from './v2/trainer/courses-v2/ra/ra.component';
import { AvatComponent } from './v2/trainer/courses-v2/avat/avat.component';
import { NewPinComponent } from './v2/shared/login-components/new-pin/new-pin.component';
import { ModuleListV2Component } from './v2/trainer/module-list-v2/module-list-v2.component';

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "Dashboard", component: AdminDashboardComponent },
  { path: "Courses", component: CoursesComponent },
  { path: "Trainees", component: TraineesComponent },
  { path: "Logout", component: LogoutComponent },
  { path: "UpdatePassword", component: ChangePasswordComponent},
  { path: "AccountVerify/:empID", component: VerifyEmailComponent},
  { path: "ForgotPassword/:empID", component: ForgotPasswordComponent},
  { path: "CoursesV2/VIS2", component: Vis2Component},
  { path: "V2/ActivityV2", component: TraineesV2Component},
  { path: "V2/TraineesDetailsV2", component: TraineesDetailsV2Component},
  { path: "V2/Clients", component: ClientsComponent},
  { path: "V2/TraineesListV2", component: TraineesListV2Component},
  { path: "V2/ForgotPassword", component: ForgotPasswordComponentV2},
  { path: "V2/NewPassword/:org/:userID", component: NewPasswordComponent},
  { path: ":app/RegisterTrainee/:org/:userID", component: TraineeRegistrationComponent},
  { path: "V2/MyDetails", component: MyDetailsComponent},
  { path: "V2/RegisterTrainer/:userID", component: TrainerRegistrationComponent},
  { path: "CoursesV2/BOS", component: BosComponent},
  { path: "CoursesV2/FPT", component: FptComponent},
  { path: "CoursesV2/AVA", component: GreComponent},
  { path: "CoursesV2/JAN", component: JanComponent},
  { path: "CoursesV2/DEP", component: DePuyComponent},
  { path: "CoursesV2/RA", component: RaComponent},
  { path: "CoursesV2/AVAT", component: AvatComponent},
  { path: "ForgotPin/:org/:userID", component: NewPinComponent},
  { path: "Modules", component: ModuleListV2Component},
  { path: "**", component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
