<!-- This is the Activity page! -->
<app-main-nav>
    <div class="main-container">
      <div  fxLayout="row" fxLayout.lt-lg="column"  fxLayoutGap.lt-lg="5%" fxLayoutGap="0px" fxLayoutAlign="space-between">
        <div>
          <div class="text-config">Activity</div>
          <div class="med-text-config">Overview</div>
          <div  fxLayoutAlign="flex-start" fxLayout.lt-md="column"  class="search-bar-config">
              <mat-form-field style="min-width: 5%; max-width: 93%;">
                <input class="input-config" (keyup)="searchTable($event.target.value)" matInput>
                <mat-icon class="mat-icon-config" matSuffix>search</mat-icon>
              </mat-form-field>
            </div>   
        </div>
        <div *ngIf="userRole=='IMT' || userRole=='IMTLCETB'" fxFlex = "15" fxFlex.lt-md="97"  fxLayoutAlign.lt-md="start" style="min-width: 5%; max-width: 93%;">
          <button fxFlex="97" class="dropdown" mat-raised-button type="button" (click)="downloadData()">
            Results Overview
            <mat-icon>download</mat-icon>
          </button>
        </div> 
      </div>
          <div class = "table-config" *ngIf="userRole!='IMT' && userRole!='IMTLCETB'">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="Name">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Module">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Module </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Module}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Date">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header="latestTime"> Date </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Date}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Explainer">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef> Explainer </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row">
                      <div *ngIf="row.Explainer == 'Yes'">
                          <img src="/assets/2.0/AVA_tick_icon.svg" style="height: 35px;">
                      </div>
                      <div *ngIf="row.Explainer == 'No'">
                          <img src="/assets/2.0/AVA_incomplete_icon.svg" style="height: 40px; margin-left: -2px;">
                      </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Practice">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef> Practice </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> <span [ngClass]="row.practiceBeatTarget =='Yes' ? 'positive' : 'negative'">{{row.Practice}} </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Exam">
                    <mat-header-cell class="table-header-font" *matHeaderCellDef> Exam </mat-header-cell>
                    <mat-cell class="table-data-font" *matCellDef="let row">
                        <div *ngIf="row.Exam == 'Yes'">
                            <img src="/assets/2.0/AVA_tick_icon.svg" style="height: 35px;">
                        </div>
                        <div *ngIf="row.Exam == 'No'">
                            <img src="/assets/2.0/AVA_incomplete_icon.svg" style="height: 40px; margin-left: -2px;">
                        </div>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Sessions">
                    <mat-header-cell class="table-header-font" *matHeaderCellDef> Sessions </mat-header-cell>
                    <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Sessions}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Duration">
                    <mat-header-cell class="table-header-font" *matHeaderCellDef> Duration </mat-header-cell>
                    <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Duration}} </mat-cell>
                  </ng-container>

                <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showDetails(row)"></mat-row>
              </mat-table>

              <div *ngIf="isLoading" style="margin-left:0.5%; margin-top: 0.5%;">
                <div *ngFor="let _ of [].constructor(10)">
                  <app-skeleton-loading Cwidth="1580" Cheight="48"><br><br>
                  </app-skeleton-loading>
                </div>
              </div>
              <mat-paginator #paginator class="paginator-class" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
        <!--Specific for Imtech-->
        <div class = "table-config" *ngIf="userRole=='IMT' || userRole=='IMTLCETB'">
          <mat-table [dataSource]="dataSourceImtech" matSort>
              <ng-container matColumnDef="Name">
                <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Date">
                <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header="latestTime"> Date </mat-header-cell>
                <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Date}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Exam">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef> Completion </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> 
                    <div *ngIf="row.Exam == 'Yes' || row.Exam == 'No'">
                      <img src="/assets/2.0/AVA_tick_icon.svg" style="height: 35px;">
                    </div>
                    <div *ngIf="row.Exam == 'Incomplete'">
                      <circle-progress [percent] = "row.Completion" [radius]="15" space="-3"
                      outerStrokeColor="#23865a" innerStrokeColor="#021B36" outerStrokeWidth="5" innerStrokeWidth="1" animationDuration="600" 
                      [showSubtitle]="false" [showBackground]="false" [showTitle] = "false" [showZeroOuterStroke]="false"
                      [showUnits]="false" 
                      ></circle-progress>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Score">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Score </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Score}} </mat-cell>
                </ng-container>

                <!-- <ng-container matColumnDef="Sessions">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef> Sessions </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Sessions}} </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="Duration">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef> Duration </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Duration}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Action">
                  <mat-header-cell class="table-header-font"  *matHeaderCellDef > Report </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row" (click)="downloadReport(row)">
                    <button class="view-button" mat-button> Download
                      <mat-icon style="color:#2c9085;">download</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>

              <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumnsImtech"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsImtech;"></mat-row>
            </mat-table>

            <div *ngIf="isLoading" style="margin-left:0.5%; margin-top: 0.5%;">
              <div *ngFor="let _ of [].constructor(10)">
                <app-skeleton-loading Cwidth="1580" Cheight="48"><br><br>
                </app-skeleton-loading>
              </div>
            </div>
            <mat-paginator #imTechpaginator class="paginator-class" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
</app-main-nav>
