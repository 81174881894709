import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpService } from '../../service/http.service';
import { SharedService } from '../../service/shared.service';

enum UserRole {
  VIS = 'VIS',
  Mersus = 'Mersus',
  BOS = 'BOS',
  FPT = 'FPT',
  RA = 'RA',
  AVA = 'AVA',
  JAN = 'JAN',
  IMT = 'IMT',
  IMTLCETB = 'IMTLCETB',
  DEP = 'DEP',
  AVAT = 'AVAT',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  mobileScreen = false;
  loginForm: UntypedFormGroup;
  invalidLogin: boolean;
  invalidLoginText: string;
  hidepwd = true;

  userType: string;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private _httpService: HttpService,
    private _sharedService: SharedService
  ) {}

  ngOnInit() {
    this.mobileScreen = window.innerWidth <= 575;
    this.loginForm = this.createFormGroup();
    this.invalidLogin = false;

    this._httpService.preLoginRequest()
      .subscribe(data => {
        // console.log('Application starting...')
      });
  }

  createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl("", [Validators.required, Validators.minLength(2)]),
      password: new UntypedFormControl("", [Validators.required, Validators.minLength(2)])
    });
  }

  login(): void {
    this._httpService.getLogin(this.loginForm.value)
      .subscribe(data => {
        if (!Object.values(UserRole).includes(data.role)) {
          this.handleInvalidLogin('Invalid Login! Please check your credentials.');
        } else {
          this.userType = data.userType;
          this._sharedService.setUserType(data.userType);
          this._sharedService.setRole(data.role);
          localStorage.setItem('token', data.token);
          this._httpService.setHeaders(data.token);
          this.invalidLogin = false;
          this.snackBar.open('Login successful', 'Close', { duration: 3000, horizontalPosition: "right", verticalPosition: "bottom" });

          if (this.userType === 'Admin' || this.userType === 'Superadmin') {
            this.router.navigateByUrl('/Dashboard', { state: { user: data.name } });
          }

          if (this.userType === 'User') {
            this.router.navigateByUrl('V2/TraineesDetailsV2', { state: { name: data.name, userID: data.userID } });
          }
        }
      }, error => {
        if (error.status === 429) {
          this.handleInvalidLogin('Too many incorrect attempts. Please try after some time.');
        } else if (error.status === 400) {
          this.handleInvalidLogin('Invalid Login! Please check your credentials.');
        }
      });
  }

  private handleInvalidLogin(errorMessage: string): void {
    this.invalidLoginText = errorMessage;
    this.invalidLogin = true;
    this.snackBar.open('Login Failed', 'Close', { duration: 3000, horizontalPosition: "right", verticalPosition: "bottom" });
  }
}
