import { Component, OnInit } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';

@Component({
  selector: 'app-new-pin',
  templateUrl: './new-pin.component.html',
  styleUrls: ['./new-pin.component.scss']
})
export class NewPinComponent implements OnInit {

  userID = null;
  mobileScreen = false;
  hidepin = true;
  hideconpin = true;
  resetPIN;
  org = null;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private _httpService: HttpService,
    private _sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userID = this.activatedRoute.snapshot.params["userID"];
    this.org = this.activatedRoute.snapshot.params["org"];
    if(window.innerWidth <= 575){
      this.mobileScreen = true;
    }
  }
  public pinForm = this.formBuilder.group({
    pin        : ['', Validators.compose([
      Validators.required, 
      Validators.minLength(4), 
      Validators.maxLength(9),
      this.numericCheck
    ])],
    conpin : ['', Validators.compose([Validators.required, Validators.minLength(4)])]
    }, {
      validator: this.confirmPasswordMatch('pin', 'conpin')
  });

  confirmPasswordMatch(controlName: string, matchingControlName: string) {    
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmPasswordMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }

  numericCheck(control: AbstractControl)  {
    let val = control.value;
    if (val === null || val === '') return null;
    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return {'nums': true};
    return null;
  }

  reset(){
    this.resetPIN={
      "userID": this.userID,
      "pin": this.pinForm.controls['pin'].value
    }
    if(this.org == "BOS"){
      this._httpService.updatePINV2('BOS3', this.userID, this.pinForm.controls['pin'].value)
      .subscribe(data=>{
        if(data=='Same PIN'){
          this.snackBar.open('Entered PIN is same as old PIN. Please enter a different PIN.', 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else if(data=="User doesn't exist"){
          this.snackBar.open("User doesn't exist!", 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else{
          this.snackBar.open('PIN reset successful', 'Close', {
            duration: 3000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
          this.router.navigateByUrl("");
        }
      }, error => {
        // console.log("Reset password Error", error);
      })
    }
    if(this.org == "FPT"){
      this._httpService.updatePINV2('FPT', this.userID, this.pinForm.controls['pin'].value)
      .subscribe(data=>{
        if(data=='Same PIN'){
          this.snackBar.open('Entered PIN is same as old PIN. Please enter a different PIN.', 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else if(data=="User doesn't exist"){
          this.snackBar.open("User doesn't exist!", 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else{
          this.snackBar.open('PIN reset successful', 'Close', {
            duration: 3000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
          this.router.navigateByUrl("");
        }
      }, error => {
        // console.log("Reset password Error", error);
      })
    }    
    if(this.org == "RA"){
      this._httpService.updatePINV2('RA', this.userID, this.pinForm.controls['pin'].value)
      .subscribe(data=>{
        if(data=='Same PIN'){
          this.snackBar.open('Entered PIN is same as old PIN. Please enter a different PIN.', 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else if(data=="User doesn't exist"){
          this.snackBar.open("User doesn't exist!", 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else{
          this.snackBar.open('PIN reset successful', 'Close', {
            duration: 3000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
          this.router.navigateByUrl("");
        }
      }, error => {
        // console.log("Reset password Error", error);
      })
    }
    if(this.org == "JAN"){
      this._httpService.updatePINV2('JAN', this.userID, this.pinForm.controls['pin'].value)
      .subscribe(data=>{
        if(data=='Same PIN'){
          this.snackBar.open('Entered PIN is same as old PIN. Please enter a different PIN.', 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else if(data=="User doesn't exist"){
          this.snackBar.open("User doesn't exist!", 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else{
          this.snackBar.open('PIN reset successful', 'Close', {
            duration: 3000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
          this.router.navigateByUrl("");
        }
      }, error => {
        // console.log("Reset password Error", error);
      })
    } 
    if(this.org == "DEP"){
      this._httpService.updatePINV2('DEP', this.userID, this.pinForm.controls['pin'].value)
      .subscribe(data=>{
        if(data=='Same PIN'){
          this.snackBar.open('Entered PIN is same as old PIN. Please enter a different PIN.', 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else if(data=="User doesn't exist"){
          this.snackBar.open("User doesn't exist!", 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else{
          this.snackBar.open('PIN reset successful', 'Close', {
            duration: 3000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
          this.router.navigateByUrl("");
        }
      }, error => {
        // console.log("Reset password Error", error);
      })
    } 
    if(this.org == "AVA"){
      this._httpService.updatePINV2('AvaDemo', this.userID, this.pinForm.controls['pin'].value)
      .subscribe(data=>{
        if(data=='Same PIN'){
          this.snackBar.open('Entered PIN is same as old PIN. Please enter a different PIN.', 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else if(data=="User doesn't exist"){
          this.snackBar.open("User doesn't exist!", 'Close', {
            duration: 5000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
        }
        else{
          this.snackBar.open('PIN reset successful', 'Close', {
            duration: 3000,
            "horizontalPosition": "right",
            "verticalPosition": "bottom",
          });
          this.router.navigateByUrl("");
        }
      }, error => {
        // console.log("Reset password Error", error);
      })
    } 
}
}
