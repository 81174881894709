import { Component, OnInit, ViewChild } from '@angular/core';
 import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
 import { Router, ActivatedRoute } from '@angular/router';
 import {HttpService} from '../../shared/service/http.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  dataSource= new MatTableDataSource();
  dataSourceModules= new MatTableDataSource();
  displayedColumns = ['Name']
  displayedColumnsModules = ['FullName', 'Users', 'Duration'];
  search : String ="";
  tempData=[];
  userRole: any;
  userType: any;
  isLoadingCourse = true;
  isLoadingModule = true;
  showModules = true;

  constructor(
    private router: Router,
    private _httpService: HttpService) { }

@ViewChild(MatSort, { static: true }) sort: MatSort;
@ViewChild('paginator') paginator: MatPaginator;

ngOnInit() {
  this._httpService.getAuthentication()
  .subscribe(data =>{
    this.userType = data.userType;
    this.userRole = data.role;
    if(this.userType == 'Admin'){
      if(this.userRole == 'VIS' || this.userRole == 'Mersus'){
        this._httpService.getCourses('VIS2').subscribe(data=>{
        this.isLoadingCourse = false;
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource = data;
        }, error =>{
          this.isLoadingCourse = false
          //console.log("Trainees Table  Error", error);
        }) 
      this._httpService.getModules('VIS2').subscribe(data=>{
        this.isLoadingModule = false;
        this.dataSourceModules.sort = this.sort;
        // this.dataSourceModules.paginator = this.paginator;
        this.dataSourceModules = data;
        }, error =>{
          this.isLoadingModule = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'BOS' || this.userRole == 'Mersus'){
        this._httpService.getCourses('BOS3').subscribe(data=>{
        this.isLoadingCourse = false;
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource = data;
        }, error =>{
          this.isLoadingCourse = false
          //console.log("Trainees Table  Error", error);
        }) 
        this._httpService.getModules('BOS3').subscribe(data=>{
        this.isLoadingModule = false;
        this.dataSourceModules.sort = this.sort;
        // this.dataSourceModules.paginator = this.paginator;
        this.dataSourceModules = data;
        }, error =>{
          this.isLoadingModule = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'FPT' || this.userRole == 'Mersus'){
        this._httpService.getCourses('FPT').subscribe(data=>{
        this.isLoadingCourse = false;
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource = data;
        }, error =>{
          this.isLoadingCourse = false
          //console.log("Trainees Table  Error", error);
        }) 
        this._httpService.getModules('FPT').subscribe(data=>{
        this.isLoadingModule = false;
        this.dataSourceModules.sort = this.sort;
        // this.dataSourceModules.paginator = this.paginator;
        this.dataSourceModules = data;
        }, error =>{
          this.isLoadingModule = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'RA' || this.userRole == 'Mersus'){
      this._httpService.getCourses('RA').subscribe(data=>{
        this.isLoadingCourse = false;
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource = data;
        }, error =>{
          this.isLoadingCourse = false
          //console.log("Trainees Table  Error", error);
        })  
      this._httpService.getModules('RA').subscribe(data=>{
        this.isLoadingModule = false;
        this.dataSourceModules.sort = this.sort;
        // this.dataSourceModules.paginator = this.paginator;
        this.dataSourceModules = data;
        }, error =>{
          this.isLoadingModule = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'AVA' || this.userRole == 'Mersus'){
        this._httpService.getCourses('AvaDemo').subscribe(data=>{
          this.isLoadingCourse = false;
          this.dataSource.sort = this.sort;
          // this.dataSource.paginator = this.paginator;
          this.dataSource = data;
          }, error =>{
            this.isLoadingCourse = false
            //console.log("Trainees Table  Error", error);
          })  
        this._httpService.getModules('AvaDemo').subscribe(data=>{
          this.isLoadingModule = false;
          this.dataSourceModules.sort = this.sort;
          // this.dataSourceModules.paginator = this.paginator;
          this.dataSourceModules = data;
          }, error =>{
            this.isLoadingModule = false
            //console.log("Trainees Table  Error", error);
          }) 
        }
      if(this.userRole == 'JAN' || this.userRole == 'Mersus'){
        this._httpService.getCourses('JAN').subscribe(data=>{
          this.isLoadingCourse = false;
          this.dataSource.sort = this.sort;
          // this.dataSource.paginator = this.paginator;
          this.dataSource = data;
          }, error =>{
            this.isLoadingCourse = false
            //console.log("Trainees Table  Error", error);
          })  
        this._httpService.getModules('JAN').subscribe(data=>{
          this.isLoadingModule = false;
          this.dataSourceModules.sort = this.sort;
          // this.dataSourceModules.paginator = this.paginator;
          this.dataSourceModules = data;
          }, error =>{
            this.isLoadingModule = false
            //console.log("Trainees Table  Error", error);
          }) 
        }
      if(this.userRole == 'DEP' || this.userRole == 'Mersus'){
        this._httpService.getCourses('DEP').subscribe(data=>{
          this.isLoadingCourse = false;
          this.dataSource.sort = this.sort;
          // this.dataSource.paginator = this.paginator;
          this.dataSource = data;
          }, error =>{
            this.isLoadingCourse = false
            //console.log("Trainees Table  Error", error);
          })  
        this._httpService.getModules('DEP').subscribe(data=>{
          this.isLoadingModule = false;
          this.dataSourceModules.sort = this.sort;
          // this.dataSourceModules.paginator = this.paginator;
          this.dataSourceModules = data;
          }, error =>{
            this.isLoadingModule = false
            //console.log("Trainees Table  Error", error);
          }) 
        }
    }
    }, error=>{
      if(error.status == 401){
        this.router.navigateByUrl("/Logout", { state: { error: '401' }});
      }
    })    
  }

  sortRows(prop, arr) {
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (a, b) {
        var i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        return b > a ? 1 : b < a ? -1 : 0;
    });
    return arr;
};

  searchTable(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // showDetails(userData) {
  //   this.router.navigateByUrl("Modules", { state: {name: userData.Name}});
  // }

  showDetails(moduleData) {
    if(this.userRole == 'BOS')
    this.router.navigateByUrl("/CoursesV2/BOS", { state: {courseName: moduleData.name}});
    if(this.userRole == 'FPT')
    this.router.navigateByUrl("/CoursesV2/FPT", { state: {courseName: moduleData.name}});
    if(this.userRole == 'VIS')
    this.router.navigateByUrl("/CoursesV2/VIS2", { state: {courseName: moduleData.name}});
    if(this.userRole == 'RA')
    this.router.navigateByUrl("/CoursesV2/RA", { state: {courseName: moduleData.name}});
    if(this.userRole == 'AVA')
    this.router.navigateByUrl("/CoursesV2/AVA", { state: {courseName: moduleData.name}});
    if(this.userRole == 'JAN')
    this.router.navigateByUrl("/CoursesV2/JAN", { state: {courseName: moduleData.name}});
    if(this.userRole == 'DEP')
      this.router.navigateByUrl("/CoursesV2/DEP", { state: {courseName: moduleData.name}});
  }

}
